define("ftapi-core/util/browser-utility", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.FirefoxIOS = _exports.ChromeIOS = _exports.ChromeAndroid = _exports.SafariMobile = _exports.Chrome = _exports.Safari = _exports.IE = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var BROWSER_KEY_IE_LEGACY = "msie";
  var BROWSER_KEY_IE_MODERN = "trident";
  var BROWSER_IE_VERSIONS = ["8.0", "9.0", "10.0", "11.0"];
  var IE = "IE";
  _exports.IE = IE;
  var Chrome = "Chrome";
  _exports.Chrome = Chrome;
  var Safari = "Safari";
  _exports.Safari = Safari;
  var ChromeAndroid = "ChromeAndroid";
  _exports.ChromeAndroid = ChromeAndroid;
  var ChromeIOS = "ChromeIOS";
  _exports.ChromeIOS = ChromeIOS;
  var FirefoxIOS = "FirefoxIOS";
  _exports.FirefoxIOS = FirefoxIOS;
  var SafariMobile = "SafariMobile";
  /**
   * Util class for detecting which browser is used.
   *
   * @author Maximilian Meier
   */

  _exports.SafariMobile = SafariMobile;

  var BrowserUtility = function () {
    // The one and only way of getting global scope in all environments
    // Copied from ftapi-js-core/node_modules/ember-cli-file-saver/node_modules/file-saver/src/FileSaver.js
    // https://stackoverflow.com/q/3277182/1008999
    var _global = (typeof window === "undefined" ? "undefined" : _typeof(window)) === 'object' && window.window === window ? window : (typeof self === "undefined" ? "undefined" : _typeof(self)) === 'object' && self.self === self ? self : (typeof global === "undefined" ? "undefined" : _typeof(global)) === 'object' && global.global === global ? global : this;

    var util = {
      detectBrowser: function detectBrowser() {
        var ua = this.getUserAgent(),
            tem,
            M = ua.match(/(opera|chrome|safari|crios|fxios|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

        if (/trident/i.test(M[1])) {
          tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
          return IE;
        } else if (M[1] === 'Chrome') {
          tem = ua.match(/\b(OPR|Edge)\/(\d+)/);

          if (tem != null) {
            return tem.slice(1).join(' ').replace('OPR', 'Opera');
          } else if (/Android/i.test(ua)) {
            return ChromeAndroid;
          }
        } else if (M[1] === "Safari") {
          var isDesktopSafari = /constructor/i.test(_global.HTMLElement) || _global.safari;

          if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia != null && navigator.userAgent.includes('Safari')) {
            return /iPad|iPhone/i.test(ua) || !isDesktopSafari ? SafariMobile : Safari;
          }

          return FirefoxIOS;
        } else if (M[1] === "MSIE") {
          return IE;
        } else if (M[1] === "CriOS") {
          return ChromeIOS;
        } else if (M[1] === "FxiOS") {
          return FirefoxIOS;
        }

        return M[1];
      },
      isBrowser: function isBrowser(browserName) {
        return util.detectBrowser() === browserName;
      },
      getIEVersion: function getIEVersion() {
        var version = -1;
        var userAgent = this.getUserAgent();

        if (userAgent != null) {
          userAgent = userAgent.toLowerCase();

          if (userAgent.indexOf(BROWSER_KEY_IE_LEGACY) !== -1 && userAgent.indexOf(BROWSER_IE_VERSIONS[0]) !== -1) {
            version = 8;
          } else if (userAgent.indexOf(BROWSER_KEY_IE_LEGACY) !== -1 && userAgent.indexOf(BROWSER_IE_VERSIONS[1]) !== -1) {
            version = 9;
          } else if (userAgent.indexOf(BROWSER_KEY_IE_LEGACY) !== -1 && userAgent.indexOf(BROWSER_IE_VERSIONS[2]) !== -1) {
            version = 10;
          } else if (userAgent.indexOf(BROWSER_KEY_IE_LEGACY) !== -1) {
            version = 7;
          } else if (userAgent.indexOf(BROWSER_KEY_IE_MODERN) !== -1 && userAgent.indexOf(BROWSER_IE_VERSIONS[3]) !== -1) {
            version = 11;
          } else if (userAgent.indexOf(BROWSER_KEY_IE_MODERN) !== -1) {
            version = 12;
          }
        }

        return version;
      },
      getUserAgent: function getUserAgent() {
        return navigator.userAgent;
      },
      isNativeCryptoSupported: function isNativeCryptoSupported() {
        return window.crypto && window.crypto.subtle;
      }
    };
    return util;
  }();

  var _default = BrowserUtility;
  _exports.default = _default;
});