define("ftapi-core/crypto/composite/st-crypto-composite", ["exports", "ftapi-core/util/encoding-utility", "ftapi-core/crypto/native/native-aes-engine", "ftapi-core/crypto/native/native-rsa-engine", "ftapi-core/crypto/native/native-random-engine", "ftapi-core/logger", "ftapi-core/path-util", "ftapi-core/crypto/forge/forge-rsa-engine", "ftapi-core/crypto/forge/forge-aes-engine"], function (_exports, _encodingUtility, _nativeAesEngine, _nativeRsaEngine, _nativeRandomEngine, _logger, _pathUtil, _forgeRsaEngine, _forgeAesEngine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TAG = "SecuTransferCryptoComposite";
  /**
   * Collection of all cryptography functions/utilities for FTAPI SecuTransfer
   *
   * As a general rule, encryption results in a hex-encoded String, and decryption
   * returns a ByteArray(Uint8Array). The utility method
   * {@link encoding.bytesToString} converts a ByteArray to a UTF-8 String.
   *
   * @author Alexander Stonehouse
   */

  function CryptoComposite() {
    /**
     * Default RSA Key length
     */
    var FTAPI_STANDARD_RSA_KEY_LENGTH = 2048;
    var IV_LENGTH = 16;
    var SECUPASS_VERSION_V2_TAG = "{SecuPass_v2}";
    var CRYPTO_WORKER = _pathUtil.workerPath + "crypto-worker.js";
    /**
     * Runnable for performing actions on the CryptoWorker thread. The runnable starts a CryptoWorker and performs the given action.
     * @param   {String} domain           Domain for action ('aes', 'rsa', 'encoding', etc.)
     * @param   {String} command          Method to be executed on the worker
     * @param   {Array}    commandArguments Arguments for the given method call as an array
     *
     * @returns {RSVP.Promise} Returns a promise that is fulfilled with the result of the command.
     */

    function CryptoRunnable(domain, command, commandArguments) {
      commandArguments.unshift(domain + "." + command);
      var promise = new Promise(function (resolve, reject) {
        var worker = new Worker(CRYPTO_WORKER);

        worker.onmessage = function (event) {
          resolve(event.data); // Kill thread

          worker.terminate();
        };

        worker.onerror = function (error) {
          console.error(error);
          reject(error);
        };

        worker.postMessage(commandArguments);
      });
      return promise;
    }

    var crypto = {
      /**
      	 * Performs the complete SecuPass generation process, including
      	 * generating a salt, an RSA key pair and encrypting the Private Key with a AES key derived from the given SecuPass Key.
      	 *
      	 * @param secuPassKey
      		 * @return promise fulfilled with Object: publicKey,
      	 * encryptedPrivateKey, salt
      	 */
      registerSecuPass: function registerSecuPass(secuPassKey) {
        var _this = this;

        var secuPass = {
          salt: "",
          publicKey: "",
          encryptedPrivateKey: ""
        };
        var generateKeyPairPromise;
        var promise = new Promise(function (resolve, reject) {
          generateKeyPairPromise = _this.rsa.generateKeyPair();
          generateKeyPairPromise.then(function (keyPair) {
            var exportPublicKeyPromise = _nativeRsaEngine.default.exportPublicKeyInSPKIFormat(keyPair.publicKey);

            exportPublicKeyPromise.then(function (exportedPublicKey) {
              var publicKeyAsPEM = _forgeRsaEngine.default.convertBinaryPublicKeyToPEM(exportedPublicKey);

              secuPass.publicKey = publicKeyAsPEM;
            }).catch(reject);

            var exportPrivateKeyPromise = _nativeRsaEngine.default.exportPrivateKeyInPKCS8Format(keyPair.privateKey);

            exportPrivateKeyPromise.then(function (exportedPrivateKey) {
              var privateKeyAsPEM = _forgeRsaEngine.default.convertBinaryPrivateKeyToPEM(exportedPrivateKey);

              var salt = _nativeRandomEngine.default.generateRandomValues(16);

              var saltAsString = _encodingUtility.default._convertByteArrayToSafeString(salt);

              var saltAsStringBase64Encoded = btoa(saltAsString);
              secuPass.salt = saltAsStringBase64Encoded;

              var deriveKeyPromise = _this._deriveSecuPassAESKeyUsingPBKDF2(secuPassKey, saltAsString);

              deriveKeyPromise.then(function (derivedKeyAsBytes) {
                var iv = _nativeRandomEngine.default.generateRandomValues(16);

                var privateKeyAsByteArray = _encodingUtility.default._convertUTF8StringToByteArray(privateKeyAsPEM);

                var privateKeyEncryptionPromise = _this.aes._encryptWithKeyAndIV(privateKeyAsByteArray, derivedKeyAsBytes, iv);

                privateKeyEncryptionPromise.then(function (encryptedPrivateKey) {
                  var encryptedPrivateKeyHexEncoded = _encodingUtility.default.toHex(encryptedPrivateKey);

                  var secuPassV2EncryptedPrivateKey = SECUPASS_VERSION_V2_TAG + encryptedPrivateKeyHexEncoded;
                  secuPass.encryptedPrivateKey = secuPassV2EncryptedPrivateKey;
                  resolve(secuPass);
                }).catch(reject);
              }).catch(reject);
            }).catch(reject);
          }).catch(reject);
        });
        return promise;
      },

      /**
       * Performs password stretching with a given SecuPass Key.
       *
       * @param password
       *            SecuPass Key as string
       * @param salt base 64 encoded string
       * @return secuAESKey as hex-encoded AES 256 key
       */
      prepareSecuAESKey: function prepareSecuAESKey(password, salt) {
        var saltBase64Decoded = atob(salt);
        return this._deriveSecuPassAESKeyUsingPBKDF2(password, saltBase64Decoded);
      },

      /**
       * Derives a 256Bit key using PBKDF2 from given password and salt
       * @param   {string} password
       * @param   {string} salt
       * @returns {Promise} Resolving the derived key as bytes
       */
      _deriveSecuPassAESKeyUsingPBKDF2: function _deriveSecuPassAESKeyUsingPBKDF2(password, salt) {
        return new Promise(function (resolve, reject) {
          var passwordHexEncoded = _encodingUtility.default.toHex(password);

          var derivedKey;

          try {
            derivedKey = _forgeAesEngine.default.deriveKey256BitUsingPBKDF2(passwordHexEncoded, salt);
          } catch (e) {
            reject(e);
          }

          var derivedKeyAsBytes = _encodingUtility.default._convertSafeStringToByteArray(derivedKey);

          resolve(derivedKeyAsBytes);
        });
      },

      /**
       * Encrypts Access Descriptor using RSA encryption.
       *
       * @param accessKey The plain access key as hex-encoded String
       * @param publicKeyPEM
       *            PEM-formatted Public Key
       * @return encryptedAccessDescriptor The access descriptor as KeyTransRecipientInfo (See RFC 5652) object
       *	                including the encrypted AES 256 Access Key
       */
      encryptAccessDescriptor: function encryptAccessDescriptor(accessKey, publicKeyPEM) {
        var _this2 = this;

        return new Promise(function (resolve, reject) {
          (0, _logger.d)(TAG, "Preparing to encrypt access descriptor!");

          _this2.rsa.encrypt(accessKey, publicKeyPEM).then(resolve).catch(reject);
        });
      },

      /**
       * Decrypts Access Descriptor using the decrypted Private Key.
       *
       * @param encryptedAccessDescriptor The encrypted access descriptor string
       *									as it is stored in the access descriptor on server side.
       * @param privateKeyPEM
       *            decrypted Private Key PEM-formatted
       * @return decryptedAccessDescriptor as hex-encoded String
       */
      decryptAccessDescriptor: function decryptAccessDescriptor(encryptedAccessDescriptor, privateKeyPEM) {
        return new Promise(function (resolve, reject) {
          (0, _logger.d)(TAG, "Preparing to decrypt access descriptor");
          crypto.rsa.decrypt(encryptedAccessDescriptor, privateKeyPEM).then(function (decryptedAccessDescriptorAsBinaryString) {
            (0, _logger.d)(TAG, "Decrypted access descriptor");
            resolve(decryptedAccessDescriptorAsBinaryString);
          }).catch(reject);
        });
      },

      /**
       * Decrypts an encrypted message using the given AES-256 access key.
       *
       * @param {String} Encrypted message encoded Base64
       * @param {ArrayBuffer} Access Key as bytes
       * @returns {String} Decrypted message
       */
      decryptMessage: function decryptMessage(base64EncodedMessage, accessKey) {
        if (base64EncodedMessage === undefined || base64EncodedMessage === null || base64EncodedMessage === "") {
          return new Promise(function (resolve) {
            resolve("");
          });
        }

        var messageDecoded = atob(base64EncodedMessage);

        var messageDecodedBytes = _encodingUtility.default._convertSafeStringToByteArray(messageDecoded);

        var promise = new Promise(function (resolve, reject) {
          crypto.aes.decrypt(messageDecodedBytes, accessKey).then(function (decryptedBytes) {
            var decryptedBody = _encodingUtility.default._convertByteArrayToUTF8String(decryptedBytes);

            resolve(decryptedBody);
          }).catch(reject);
        });
        return promise;
      },

      /**
       * Encrypts a message using the given AES-256 access key.
       *
       * @param {String} message as UTF-8 String
       * @param {ArrayBuffer} Access Key as bytes
       * @returns {String} encrypted message Base64 encoded
       */
      encryptMessage: function encryptMessage(message, accessKey) {
        var messageAsBytes = _encodingUtility.default._convertUTF8StringToByteArray(message);

        var promise = new Promise(function (resolve, reject) {
          crypto.aes.encrypt(messageAsBytes, accessKey).then(function (encryptedBytes) {
            var encrypted = _encodingUtility.default.toBase64(encryptedBytes);

            resolve(encrypted);
          }).catch(reject);
        });
        return promise;
      },

      /**
       * Does the same as {@link #aes.decrypt()} except that it strips the version tag from the beginning.
       *
       * @param encryptedPrivateKey (with version tag)
       * @param {ArrayBuffer} secuAESKey raw bytes
       * @return decrypted PEM-formatted private key
       */
      decryptPrivateKey: function decryptPrivateKey(encryptedPrivateKey, secuPassAESKey) {
        var encryptedPrivateKeyWithoutTag = encryptedPrivateKey.substring(SECUPASS_VERSION_V2_TAG.length, encryptedPrivateKey.length);

        var encryptedPrivateKeyBytes = _encodingUtility.default.fromHex(encryptedPrivateKeyWithoutTag);

        return crypto.aes.decrypt(encryptedPrivateKeyBytes, secuPassAESKey);
      },
      rsa: {
        encrypt: function encrypt(data, publicKeyAsPEM) {
          return new Promise(function (resolve, reject) {
            try {
              var encryptedData = _forgeRsaEngine.default.encryptWithRSAPKCS1(publicKeyAsPEM, data);

              resolve(encryptedData);
            } catch (e) {
              reject(e);
            }
          });
        },
        decrypt: function decrypt(data, privateKeyPEM) {
          return new Promise(function (resolve, reject) {
            try {
              var decryptedData = _forgeRsaEngine.default.decryptWithRSAPKCS1(privateKeyPEM, data);

              resolve(decryptedData);
            } catch (e) {
              reject(e);
            }
          });
        },
        generateKeyPair: function generateKeyPair() {
          return new Promise(function (resolve, reject) {
            _nativeRsaEngine.default.generateKeyPairForRSAPKCS1(2048).then(resolve).catch(reject);
          });
        }
      },
      aes: {
        encrypt: function encrypt(dataAsArrayBuffer, keyAsBytes) {
          var iv = this.generateRandomIV();
          return this._encryptWithKeyAndIV(dataAsArrayBuffer, keyAsBytes, iv);
        },
        _encryptWithKeyAndIV: function _encryptWithKeyAndIV(dataAsArrayBuffer, keyAsBytes, iv) {
          var _this3 = this;

          return new Promise(function (resolve, reject) {
            var importKeyPromise = _nativeAesEngine.default.importKey256BitForAESCBC(keyAsBytes);

            importKeyPromise.then(function (importedKey) {
              _this3._encryptWithCryptoKey(dataAsArrayBuffer, importedKey, iv).then(resolve).catch(reject);
            }).catch(reject);
          });
        },
        _encryptWithCryptoKey: function _encryptWithCryptoKey(dataAsArrayBuffer, cryptoKey, iv) {
          if (dataAsArrayBuffer.length === 0) {
            // If there is no data, then no need to encrypt
            return new Promise(function (resolve) {
              return resolve(new Uint8Array());
            });
          }

          return new Promise(function (resolve, reject) {
            var encryptionPromise = _nativeAesEngine.default.encryptWithAESCBC(cryptoKey, iv, dataAsArrayBuffer);

            encryptionPromise.then(function (encryptedDataBytes) {
              var encryptionResult = new Uint8Array(iv.byteLength + encryptedDataBytes.byteLength);
              encryptionResult.set(new Uint8Array(iv), 0);
              encryptionResult.set(new Uint8Array(encryptedDataBytes), iv.byteLength);
              resolve(encryptionResult);
            }).catch(reject);
          });
        },
        decrypt: function decrypt(dataAsArrayBuffer, keyAsBytes) {
          return new Promise(function (resolve, reject) {
            var keyImportPromise = _nativeAesEngine.default.importKey256BitForAESCBC(keyAsBytes);

            keyImportPromise.then(function (key) {
              var iv = dataAsArrayBuffer.subarray(0, IV_LENGTH);
              var encryptedDataAsArrayBuffer = dataAsArrayBuffer.subarray(IV_LENGTH, dataAsArrayBuffer.length);

              var decryptionPromise = _nativeAesEngine.default.decryptWithAESCBC(key, iv, encryptedDataAsArrayBuffer);

              decryptionPromise.then(function (decryptedDataAsArrayBuffer) {
                resolve(new Uint8Array(decryptedDataAsArrayBuffer));
              }).catch(reject);
            }).catch(reject);
          });
        },
        generateRandomKey: function generateRandomKey() {
          var _this4 = this;

          return new Promise(function (resolve, reject) {
            _nativeAesEngine.default.generateKey256BitForAESCBC().then(function (cryptoKey) {
              _this4._exportCryptoKey(cryptoKey).then(resolve).catch(reject);
            }).catch(reject);
          });
        },
        generateRandomIV: function generateRandomIV() {
          var iv = _nativeRandomEngine.default.generateRandomValues(16);

          return iv;
        },
        _exportCryptoKey: function _exportCryptoKey(cryptoKey) {
          var keyExportPromise = _nativeAesEngine.default.exportKeyAsJWK(cryptoKey);

          return keyExportPromise.then(function (derivedKeyInJWK) {
            var base64EncodedDerivedKeyBytes = _encodingUtility.default.convertBase64UrlToBase64(derivedKeyInJWK.k);

            var derivedKeyBytesAsString = atob(base64EncodedDerivedKeyBytes);

            var key = _encodingUtility.default._convertSafeStringToByteArray(derivedKeyBytesAsString);

            return key;
          });
        }
      },
      hashing: {
        md5: function md5(data) {
          return new CryptoRunnable("hashing", "md5", [data]);
        },
        sha512: function sha512(data) {
          return new CryptoRunnable("hashing", "sha512", [data]);
        }
      },
      password: {
        generate: function generate(password, salt) {
          return new Promise(function (resolve, reject) {
            crypto.password.hash(password, salt).then(function (hash) {
              resolve(hash.substring(29, hash.length));
            }).catch(reject);
          });
        }
      },

      /**
       * Private method for testing purposes only
       * @param {Number} keyLength Default RSA key length
       */
      _setDefaultKeyLength: function _setDefaultKeyLength(keyLength) {
        FTAPI_STANDARD_RSA_KEY_LENGTH = keyLength;
      }
    };
    return crypto;
  }

  var _default = CryptoComposite;
  _exports.default = _default;
});