define("ftapi-core/models/st-mail", ["exports", "ember-data", "ftapi-core/models/mail"], function (_exports, _emberData, _mail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SECURITY_LEVEL_4 = _exports.SECURITY_LEVEL_3 = _exports.SECURITY_LEVEL_2 = _exports.SECURITY_LEVEL_1 = void 0;
  var SECURITY_LEVEL_1 = 0;
  _exports.SECURITY_LEVEL_1 = SECURITY_LEVEL_1;
  var SECURITY_LEVEL_2 = 10;
  _exports.SECURITY_LEVEL_2 = SECURITY_LEVEL_2;
  var SECURITY_LEVEL_3 = 20;
  _exports.SECURITY_LEVEL_3 = SECURITY_LEVEL_3;
  var SECURITY_LEVEL_4 = 30;
  /**
   * Creates a new mail object with custom properties for SecuTransfer.
   *
   * @author Alexander Stonehouse
   * @returns {Mail} created Mail
   */

  _exports.SECURITY_LEVEL_4 = SECURITY_LEVEL_4;

  var SecuTransferMail = _mail.default.extend({
    toRecipients: _emberData.default.attr("array", {
      transient: true
    }),
    ccRecipients: _emberData.default.attr("array", {
      transient: true
    }),
    encrypted: _emberData.default.attr("boolean", {
      transient: true
    }),
    expirationPeriod: _emberData.default.attr("number"),
    securityLevel: _emberData.default.attr("number")
  });

  var _default = SecuTransferMail;
  _exports.default = _default;
});