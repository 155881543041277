define("ftapi-core/workers/crypto-worker", [], function () {
  "use strict";

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  /**
   * Collection of all cryptography functions/utilities for FTAPI's SecuPass
   * encryption suite.
   *
   * All of the functions are run as a WebWorker in a background thread.
   *
   * @author Alexander Stonehouse
   */
  importScripts("../crypto/forge.min.js", "../crypto/bCrypt.js", "../crypto/isaac.js");
  /**
   * Web Worker for performing cryptography on a background thread.
   *
   * @author Alexander Stonehouse
   * @constructor
   */

  var CryptoWorker = function () {
    var MD5_PREFX = "{MD5}";
    var SHA512_PREFIX = "{SHA-512}";
    var crypto = {
      rsa: {
        /**
         *
         * @deprecated use crypto-utility!
         *
         * @param data
         *            as hex-encoded String
         * @param privateKeyPEM
         *            decrypted Private Key PEM-formatted
         *            @return decryptedBytes decrypted data as ByteArray
         */
        decrypt: function decrypt(data, privateKeyPEM) {
          var privateKey = forge.pki.privateKeyFromPem(privateKeyPEM);
          var dataBytes = forge.util.binary.hex.decode(data);
          var decrypted = privateKey.decrypt(dataBytes);
          decrypted = forge.util.decode64(decrypted);

          var decryptedBytes = crypto.encoding._convertSafeStringToByteArray(decrypted);

          return decryptedBytes;
        }
      },
      aes: {
        /**
         * Encrypts given data with AES-CBC with a given 256 bit key. The
         * data is always converted into a ByteArray if not already, and
         * then put into a forgeJS {@link ByteStringBuffer}. Result is
         * natively returned as a string of bytes, but is then hex-encoded.
         *
         * @param data
         *            as either a UTF-8 String or ByteArray
         * @param key
         *            as Hex encoded AES 256 bit key
         * @return encrypted data as ByteArray
         */
        encrypt: function encrypt(data, key, hexIv) {
          if (typeof data === "string") {
            // If it's a string, we need to convert to ByteArray
            data = crypto.encoding._convertUTF8StringToByteArray(data);
          }

          var keyBytes = forge.util.hexToBytes(key);
          var cipher = forge.cipher.createCipher('AES-CBC', keyBytes);
          var iv = forge.util.hexToBytes(hexIv);
          cipher.start({
            iv: iv
          }); // Create buffer with data

          var buffer = forge.util.createBuffer(data);
          cipher.update(buffer);
          cipher.finish();
          var encrypted = cipher.output.data; // Encode binary string into hex

          var encryptedHex = forge.util.binary.hex.encode(encrypted); // We now need to merge the IV and encrypted data, so the dynamic IV can be used during decryption
          // encrypted Hex into a ByteArray

          var encryptedBytes = crypto.encoding.fromHex(encryptedHex); // IV into ByteArray

          var ivBytes = crypto.encoding.fromHex(hexIv); // Create sufficiently large array for both

          var result = new Uint8Array(ivBytes.byteLength + encryptedBytes.byteLength); // Add the IV first

          result.set(new Uint8Array(ivBytes), 0); // Append the encrypted data

          result.set(new Uint8Array(encryptedBytes), ivBytes.byteLength);
          return result;
        },

        /**
         * Decrypts hex-encoded data using the given 256 AES key. Results is
         * natively returned as a string of bytes, but is converted into a
         * ByteArray.
         *
         * @param encryptedData
         *            as hex-encoded String or ByteArray
         * @param key
         *            as hex-encoded AES 256 bit key
         * @return unencrypted data as ByteArray
         */
        decrypt: function decrypt(encryptedData, key) {
          var keyBytes = forge.util.hexToBytes(key);
          var decipher = forge.cipher.createDecipher('AES-CBC', keyBytes); // We first need to extract the IV from the first 16 bytes

          if (!forge.util.isArrayBufferView(encryptedData)) {
            console.error(encryptedData);
            throw "Data must be ArrayBuffer! [" + _typeof(encryptedData) + "]";
          } // Create array for IV


          var ivHex = new Uint8Array(16); // Set data for IV

          ivHex.set(encryptedData.subarray(0, 16)); // Convert to hex (so that it can be later converted to string-backed byte buffer

          ivHex = crypto.encoding.toHex(ivHex); // ByteArray for encrypted data

          var encryptedDataHex = new Uint8Array(encryptedData.length - 16); // Set encrypted data

          encryptedDataHex.set(encryptedData.subarray(16, encryptedData.length)); // Encode to hex

          encryptedDataHex = crypto.encoding.toHex(encryptedDataHex); // We now convert IV and Data from Hex to a ByteStringBuffer, which is currently what forge processes for encrypted. 
          // This is because Forge was written before the introduction of the ByteArray APIs. A transition is currently underway
          // but is not yet supported in the current version.

          var encryptedDataByteString = forge.util.hexToBytes(encryptedDataHex);
          var iv = forge.util.hexToBytes(ivHex);
          decipher.start({
            iv: iv
          });
          var buffer = forge.util.createBuffer(encryptedDataByteString);
          decipher.update(buffer);
          decipher.finish();
          var unencrypted = decipher.output.data;

          var unencryptedBytes = crypto.encoding._convertSafeStringToByteArray(unencrypted);

          return unencryptedBytes;
        }
      },
      password: {
        /**
         * Stretches user password with BCrypt/HKDF using given salt. Password is
         * converted to hex before encoding.
         *
         * Note: salt should be from a BCrypt hash
         *
         * @param password
         *            as UTF-8 String
         * @param salt
         *            as UTF-8 String
         * @return stretched password as hex-encoded String 256-bit AES Key
         */
        stretch: function stretch(password, salt) {
          // Initially stretch with BCrypt, results in 192 bit key
          var bCryptHash = crypto.password.hash(password, salt); // We use the BCrypt salt again with HMac

          var saltHex = crypto.password._extractSaltFromBCryptSalt(salt);

          var bCryptPassword = crypto.password._extractPasswordHashFromBCryptHash(bCryptHash); // We now use HMac through HKDF spec to stretch BCrypt to 256 bit


          return crypto.password._generateHKDFHash(bCryptPassword, saltHex);
        },
        salt: function salt(cost) {
          var bcrypt = new bCrypt(); // jshint ignore:line

          var salt;

          try {
            salt = bcrypt.gensalt(cost);
            return salt;
          } catch (error) {
            console.error(error);
            throw error;
          }
        },

        /**
         * Creates BCrypt hash from given plain-text password and salt (BCrypt formatted salt)
         * @param   {String} password Plaint text
         * @param   {String} salt     Bcrypt formatted salt
         * @returns {String} formatted BCrypt hash
         */
        hash: function hash(password, salt) {
          var bcrypt = new bCrypt(); // jshint ignore:line

          try {
            var result;
            bcrypt.hashpw(password, salt, function (pwHash) {
              result = pwHash;
            }, null);
            return result;
          } catch (error) {
            console.error(error);
            throw error;
          }
        },

        /**
         * Verifies given BCrypt hash with the given password
         * @param   {String} password     Plaint text password
         * @param   {String} passwordHash BCrypt hash from given password
         * @returns {Boolean} true if hash and password match
         */
        verify: function verify(password, passwordHash) {
          var result;
          var bcrypt = new bCrypt(); // jshint ignore:line

          try {
            bcrypt.checkpw(password, passwordHash, function (accessGranted) {
              result = accessGranted;
            }, null);
            return result;
          } catch (error) {
            console.error(error);
            throw error;
          }
        },

        /**
         * Extracts the BCrypt password hash from a complete BCrypt hash, and then encodes it to hex.
         * @param   {String}   bCryptHash Complete BCrypt hash
         * @returns {String} Hex-encoded BCrypt password hash
         */
        _extractPasswordHashFromBCryptHash: function _extractPasswordHashFromBCryptHash(bCryptHash) {
          var bCryptPassword = bCryptHash.substr(29, bCryptHash.size);
          var bCryptPasswordHex = crypto.encoding.toHex(bCryptPassword);
          return bCryptPasswordHex;
        },

        /**
         * Removes the BCrypt prefix information from a BCrypt salt
         * @param   {String}   bCryptSalt BCrypt salt including prefix
         * @returns {String} hex-encoded BCrypt salt
         */
        _extractSaltFromBCryptSalt: function _extractSaltFromBCryptSalt(bCryptSalt) {
          var salt = bCryptSalt.substring(7, bCryptSalt.length);
          var saltHex = crypto.encoding.toHex(salt);
          return saltHex;
        },

        /**
         * Performs HKDF on the given data with key. Supports only single iterations and always returns a 256-bit key.
         * @param   {String} data Hex-encoded data
         * @param   {String} key  Hex-encoded key
         * @returns {String} Hex-encoded HMac hash resulting from HKDF (256-bit)
         */
        _generateHKDFHash: function _generateHKDFHash(data, key) {
          // Need to first decode hex to forge's ByteStrings, which are processed by the HMac digest
          var dataBytes = forge.util.hexToBytes(data);
          var keyBytes = forge.util.hexToBytes(key);
          var seedHmac = forge.hmac.create();
          seedHmac.start('sha256', keyBytes);
          seedHmac.update(dataBytes);
          var seed = seedHmac.digest().toHex(); // Resulting hash of data and key is used as seed

          var seedBytes = forge.util.hexToBytes(seed);
          var stretchHMac = forge.hmac.create();
          stretchHMac.start('sha256', seedBytes); // We now add an iteration bit marker, this would be iterated higher if we did more than one iteration

          var indexMarkerBit = forge.util.hexToBytes("1");
          stretchHMac.update(indexMarkerBit); // Note that this isn't a proper HKDF implementation because there is no iteration, but it's not necessary
          // for keys equal to or smaller to the size of an HMac hash which is 256-bit (i.e. exactly what we need)

          return stretchHMac.digest().toHex();
        }
      },
      hashing: {
        /**
         * Performs MD5 hash on given data
         *
         * @param data
         * @return hash as hex-encoded String
         */
        md5: function md5(data) {
          var hasher = forge.md.md5.create();
          hasher.update(data);
          var hash = hasher.digest().toHex();
          return MD5_PREFX + hash;
        },

        /**
         * Performs SHA-512 hash on given data
         *
         * @param data
         *            as String
         * @return hash as hex-encoded String
         */
        sha512: function sha512(data) {
          var hasher = forge.md.sha512.create();
          hasher.update(data);
          var hash = hasher.digest().toHex();
          return SHA512_PREFIX + hash;
        }
      },
      encoding: {
        /**
         * Converts ByteArray/String to Hex-encoded String
         *
         * @param data
         *            as ByteArray or UTF-8 String
         * @return hex-encoded String
         */
        toHex: function toHex(data) {
          if (typeof data === "string") {
            data = crypto.encoding._convertUTF8StringToByteArray(data);
          }

          var hex = forge.util.binary.hex.encode(data);
          return hex;
        },

        /**
         * Converts hex-encoded String to ByteArray
         *
         * @param string
         *            hex-encoded
         * @return ByteArray representing data from hex String
         */
        fromHex: function fromHex(string) {
          var byteArray = forge.util.binary.hex.decode(string);
          return byteArray;
        },

        /**
         * Converts a ByteArray to a UTF-8 String
         *
         * @param bytes
         *            ByteArray
         * @return UTF-8 encoded String
         */
        bytesToString: function bytesToString(bytes) {
          return crypto.encoding._convertByteArrayToUTF8String(bytes);
        },

        /**
         * Converts UTF-8 String to a SafeString. This is necessary because
         * standard UTF-8 can't be serialized to Base64 or Hex. UTF-8 must
         * always be converted to a SafeString to ensure consistent
         * serialization in comparison to Java etc.
         *
         * Note that the resulting String appears to lose encoding, but can
         * be successfully reencoded to UTF-8 with
         * {@link #_convertSafeStringToUTF8String}.
         */
        _convertUTF8StringToSafeString: function _convertUTF8StringToSafeString(str) {
          // First encode string to URI encoding, safely storing UTF-8
          // encoding
          var URIEncodedString = encodeURIComponent(str); // Convert this back, which initially destroys the proper UTF-8
          // encoding, but is now safe for serialization.

          var decodedSafeString = unescape(URIEncodedString);
          return decodedSafeString;
        },

        /**
         * Convert a SafeString back to a UTF-8 String. This is necessary
         * after decoding from Hex or Base64. Restores all proper UTF-8
         * encoding.
         */
        _convertSafeStringToUTF8String: function _convertSafeStringToUTF8String(str) {
          // Convert the SafeString back to URI encoding
          var URIEncodedString = escape(str); // Decode URI encoding back to standard UTF-8

          var UTF8String = decodeURIComponent(URIEncodedString);
          return UTF8String;
        },
        _convertUTF8StringToByteArray: function _convertUTF8StringToByteArray(str) {
          var safeString = crypto.encoding._convertUTF8StringToSafeString(str);

          return crypto.encoding._convertSafeStringToByteArray(safeString);
        },
        _convertSafeStringToByteArray: function _convertSafeStringToByteArray(safeString) {
          var result = new Uint8Array(safeString.length);

          for (var i = 0; i < safeString.length; i++) {
            result[i] = safeString.charCodeAt(i);
          }

          return result;
        },
        _convertByteArrayToUTF8String: function _convertByteArrayToUTF8String(byteArray) {
          var safeString = crypto.encoding._convertByteArrayToSafeString(byteArray);

          var string = crypto.encoding._convertSafeStringToUTF8String(safeString);

          return string;
        },
        _convertByteArrayToSafeString: function _convertByteArrayToSafeString(byteArray) {
          var i = 0;
          var result = "";

          while (i < byteArray.byteLength) {
            result += String.fromCharCode(byteArray[i]);
            i++;
          }

          return result;
        }
      }
    };
    return crypto;
  }();

  onmessage = function onmessage(event) {
    // jshint ignore:line
    var domainAndCommand = event.data[0];

    if (typeof domainAndCommand !== "string") {
      throw "Event data must be of type String! [" + _typeof(domainAndCommand) + "]";
    }

    var split = domainAndCommand.split(".");

    if (split.length !== 2) {
      throw "Event must include domain and command! [" + domainAndCommand + "]";
    }

    var domain = CryptoWorker[split[0]];

    if (typeof domain === "undefined") {
      throw "Domain [" + split[0] + "] is not supported!";
    }

    var command = domain[split[1]];

    if (typeof command === "undefined") {
      throw "Command not found in domain " + split[0] + "! [" + split[1] + "]";
    }

    event.data.splice(0, 1);
    var result = command.apply(this, event.data);
    postMessage(result);
  };
});