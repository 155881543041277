define("ftapi-core/initializers/client-context", ["exports", "ftapi-core/client-context"], function (_exports, _clientContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /**
  * Dependency injection for {@link ClientContext}
  *
  * @author Alexander Stonehouse
  */
  function initialize(application) {
    application.register('client-context:main', _clientContext.default, {
      instantiate: true
    });
    application.inject('route', 'client', 'client-context:main');
    application.inject('adapter', 'client', 'client-context:main');
  }

  var _default = {
    name: 'client-context',
    initialize: initialize
  };
  _exports.default = _default;
});