define("ftapi-core/util/phone-utility", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Util class for phone functions.
   */
  var PhoneUtility = function () {
    var util = {
      isPhoneNumber: function isPhoneNumber(s) {
        if (s === null || s === undefined) {
          return false;
        } else {
          s = s.replace(/\s/g, '');
          var response = s.match(/^\+?[0-9]+\/?[0-9]+$/);

          if (response !== null && response[0] === s) {
            return true;
          } else {
            return false;
          }
        }
      }
    };
    return util;
  }();

  var _default = PhoneUtility;
  _exports.default = _default;
});