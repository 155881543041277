define("ftapi-core/util/file-utility", ["exports", "ftapi-core/localizations/localization"], function (_exports, _localization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Util class for working with file information.
   *
   * @author Alexander Stonehouse
   **/
  var FileUtility = function () {
    var ONE_BYTE = 1;
    var ONE_KB = 1024 * ONE_BYTE;
    var ONE_MB = 1024 * ONE_KB;
    var ONE_GB = 1024 * ONE_MB;
    var ONE_TB = 1024 * ONE_GB;
    var suffixes = {
      byteDE: " Byte",
      byte: " byte",
      bytes: " bytes",
      kb: " KB",
      mb: " MB",
      gb: " GB",
      tb: " TB"
    };
    var fileUtil = {
      ONE_BYTE: ONE_BYTE,
      ONE_KB: ONE_KB,
      ONE_MB: ONE_MB,
      ONE_GB: ONE_GB,
      bytesToString: function bytesToString(bytes, locale) {
        if (bytes < 0) {
          return "N/A";
        }

        if (!locale) {
          locale = _localization.shortLocale;
        }

        var value;
        var suffix;

        if (bytes > ONE_TB) {
          suffix = suffixes.tb;
          value = bytes / ONE_TB;
        } else if (bytes > ONE_GB) {
          suffix = suffixes.gb;
          value = bytes / ONE_GB;
        } else if (bytes > ONE_MB) {
          suffix = suffixes.mb;
          value = bytes / ONE_MB;
        } else if (bytes > ONE_KB) {
          suffix = suffixes.kb;
          value = bytes / ONE_KB;
        } else if (locale === "de") {
          suffix = suffixes.byteDE;
          value = bytes;
        } else {
          if (bytes === 1) {
            suffix = suffixes.byte;
          } else {
            suffix = suffixes.bytes;
          }

          value = bytes;
        }

        var fileSizeNum = (Math.round(value * 100) / 100).toString();

        if (bytes < ONE_MB) {
          var dotIndex = fileSizeNum.indexOf(".");

          if (dotIndex !== -1) {
            fileSizeNum = fileSizeNum.substr(0, dotIndex);
          }
        } else if (locale !== "en") {
          fileSizeNum = fileSizeNum.replace(".", ",");
        }

        return fileSizeNum + suffix;
      }
    };
    return fileUtil;
  }();

  var _default = FileUtility;
  _exports.default = _default;
});