define("ftapi-core/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var folderId = 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    primaryKey: "uuid",
    attrs: {
      files: {
        embedded: 'always'
      },
      folders: {
        embedded: 'always'
      }
    },
    modelNameFromPayloadKey: function modelNameFromPayloadKey(key) {
      var type;

      if (key === "folderChildren") {
        type = "folder-children";
      } else if (key === "status") {
        type = "mail-status";
      } else {
        type = this._super(key);
      }

      return type;
    },
    extractId: function extractId(modelClass, resourceHash) {
      if (modelClass.modelName === "folder-children") {
        folderId++;
        return folderId;
      }

      return this._super(modelClass, resourceHash);
    },
    serializeAttribute: function serializeAttribute(record, json, key, attribute) {
      if (key === "uuid" && typeof record.attr("uuid") === "undefined") {
        json.uuid = record.id;
        return;
      }

      if (attribute.options.transient) {
        return;
      }

      return this._super(record, json, key, attribute);
    }
  });

  _exports.default = _default;
});