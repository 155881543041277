define("ftapi-core/transforms/array", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  * Transform implementation for Arrays, allows JS arrays to be used as data type within Ember Data Models.
  *
  * @author Alexander Stonehouse
  */
  var ArrayTransform = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      return Ember.typeOf(serialized) === "array" ? serialized : [];
    },
    serialize: function serialize(deserialized) {
      var type = Ember.typeOf(deserialized);

      if (type === 'array') {
        return deserialized;
      } else if (type === 'string') {
        return deserialized.split(',').map(function (item) {
          return item.trim();
        });
      } else {
        return [];
      }
    }
  });

  var _default = ArrayTransform;
  _exports.default = _default;
});