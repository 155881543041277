define("ftapi-core/crypto/native/native-random-engine", ["exports", "ftapi-core/logger", "ftapi-core/util/browser-utility"], function (_exports, _logger, _browserUtility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TAG = "NativeAESEngine";
  /**
   * Random generation engine using the native window.crypto API. Shows a warning
   if the native crypto API is not available.
   *
   * @author Marco Wolff
   */

  var NativeRandomEngine = function () {
    var NATIVE_PRNG;

    if (_browserUtility.default.isNativeCryptoSupported()) {
      NATIVE_PRNG = window.crypto;
    } else {
      (0, _logger.w)(TAG, "Native Crypto API not available!");
    }

    var nativeRandomEngine = {
      /**
       * Generates random values with given length in byte.
       * @param   {Number} lengthInBytes Random values length in bytes
       * @returns {Uint8Array} given length in byte random values 
       */
      generateRandomValues: function generateRandomValues(lengthInBytes) {
        var randomValues = new Uint8Array(lengthInBytes);
        NATIVE_PRNG.getRandomValues(randomValues);
        return randomValues;
      }
    };
    return nativeRandomEngine;
  }();

  var _default = NativeRandomEngine;
  _exports.default = _default;
});