define("ftapi-core/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STATE = void 0;
  var STATE = {
    emailConfirmationSent: 20,
    emailConfirmed: 32,
    tokenSent: 36,
    tokenConfirmed: 37,
    passwordSet: 38,
    registered: 40
  };
  _exports.STATE = STATE;

  var User = _emberData.default.Model.extend({
    uuid: _emberData.default.attr("string"),
    email: _emberData.default.attr("string"),
    phone: _emberData.default.attr("string"),
    username: _emberData.default.attr("string"),
    password: _emberData.default.attr("string"),
    firstName: _emberData.default.attr("string"),
    lastName: _emberData.default.attr("string"),
    language: _emberData.default.attr("string"),
    userType: _emberData.default.attr("string"),
    state: _emberData.default.attr("number"),
    accountType: _emberData.default.attr("number"),
    passwordGenerationSalt: _emberData.default.attr("string"),
    secuPassGenerationSalt: _emberData.default.attr("string"),
    e2eEnabled: _emberData.default.attr("boolean", {
      defaultValue: false
    }),
    verifyPhone: function verifyPhone(token) {
      return new Promise(function (resolve, reject) {
        if (token === undefined) {
          token = "";
        }

        var query = Ember.$.post("/api/2/user/phone/verify/" + token + "");
        query.then(function () {
          resolve();
        });
        query.error(reject);
      });
    },
    resendToken: function resendToken() {
      return new Promise(function (resolve, reject) {
        var query = Ember.$.post("/api/2/user/phone/token/send", JSON.stringify({}));
        query.then(function (resend) {
          resolve(resend);
        });
        query.error(reject);
      });
    }
  });

  var _default = User;
  _exports.default = _default;
});