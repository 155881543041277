define("ftapi-core/util/keyboard-utility", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Utilities to help respond to keyboard events.
   *
   * @author Alexander Stonehouse
   * @type {Object} Static class
   */
  var KeyboardUtility = {
    bindEnter: function bindEnter(jQuery, handler) {
      jQuery.keydown(function (event) {
        var keyCode = event.keyCode ? event.keyCode : event.which;

        if (keyCode === 13) {
          Ember.$.proxy(handler, this)();
        }
      });
    }
  };
  var _default = KeyboardUtility;
  _exports.default = _default;
});