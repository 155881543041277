define("ftapi-core/crypto/composite/sdx-crypto-composite", ["exports", "ftapi-core/util/encoding-utility", "ftapi-core/crypto/native/native-rsa-engine", "ftapi-core/crypto/native/native-aes-engine", "ftapi-core/crypto/native/native-random-engine", "ftapi-core/crypto/forge/forge-rsa-engine", "ftapi-core/util/browser-utility", "ftapi-core/logger", "ftapi-core/path-util"], function (_exports, _encodingUtility, _nativeRsaEngine, _nativeAesEngine, _nativeRandomEngine, _forgeRsaEngine, _browserUtility, _logger, _pathUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TAG = "SDXCryptoComposite";
  /**
   * Collection of all cryptography functions/utilities for SDX.
   *
   * As a general rule, encryption results in a hex-encoded String, and decryption
   * returns a ByteArray(Uint8Array). The utility method
   * {@link encoding.bytesToString} converts a ByteArray to a UTF-8 String.
   *
   * @author Alexander Stonehouse
   */

  function CryptoComposite() {
    /**
     * Default RSA Key length
     */
    var FTAPI_STANDARD_RSA_KEY_LENGTH = 4096;
    /**
     * Default AES Key length
     */

    var FTAPI_STANDARD_AES_KEY_LENGTH = 256;
    /**
     * Default cost for a BCrypt hash
     */

    var DEFAULT_BCRYPT_HASH_COST = 10;
    var EXPECTED_DURATION_RSA_KEY_GENERATION_4096 = 120000;
    var EXPECTED_DURATION_RSA_KEY_GENERATION_2048 = 10000;
    var EXPECTED_DURATION_RSA_KEY_GENERATION_4096_NATIVE = 5000;
    var EXPECTED_DURATION_RSA_KEY_GENERATION_2048_NATIVE = 1000;
    var SECUPASS_VERSION = "{SecuPass_v3}";
    var ASN1_PREFIX_RSA_OAEP_SHA512_FOR_RSA_2048_ENCRYPTED_KEY = [0x30, 0x82, 0x01, 0x47, 0x02, 0x01, 0x02, 0x80, 0x00, 0x30, 0x3c, 0x06, 0x09, 0x2a, 0x86, 0x48, 0x86, 0xf7, 0x0d, 0x01, 0x01, 0x07, 0x30, 0x2f, 0xa0, 0x0f, 0x30, 0x0d, 0x06, 0x09, 0x60, 0x86, 0x48, 0x01, 0x65, 0x03, 0x04, 0x02, 0x03, 0x05, 0x00, 0xa1, 0x1c, 0x30, 0x1a, 0x06, 0x09, 0x2a, 0x86, 0x48, 0x86, 0xf7, 0x0d, 0x01, 0x01, 0x08, 0x30, 0x0d, 0x06, 0x09, 0x60, 0x86, 0x48, 0x01, 0x65, 0x03, 0x04, 0x02, 0x03, 0x05, 0x00];
    var ASN1_PREFIX_RSA_OAEP_SHA512_FOR_RSA_4096_ENCRYPTED_KEY = [0x30, 0x82, 0x02, 0x47, 0x02, 0x01, 0x02, 0x80, 0x00, 0x30, 0x3C, 0x06, 0x09, 0x2A, 0x86, 0x48, 0x86, 0xF7, 0x0D, 0x01, 0x01, 0x07, 0x30, 0x2F, 0xA0, 0x0F, 0x30, 0x0D, 0x06, 0x09, 0x60, 0x86, 0x48, 0x01, 0x65, 0x03, 0x04, 0x02, 0x03, 0x05, 0x00, 0xA1, 0x1C, 0x30, 0x1A, 0x06, 0x09, 0x2A, 0x86, 0x48, 0x86, 0xF7, 0x0D, 0x01, 0x01, 0x08, 0x30, 0x0D, 0x06, 0x09, 0x60, 0x86, 0x48, 0x01, 0x65, 0x03, 0x04, 0x02, 0x03, 0x05, 0x00];
    var ASN1_TAG_OCTET_STRING_RSA_2048_BIT_KEY = [0x04, 0x82, 0x01, 0x00];
    var ASN1_TAG_OCTET_STRING_RSA_4096_BIT_KEY = [0x04, 0x82, 0x02, 0x00];
    var ASN1_PREFIX_RSA_OAEP_2048 = new Uint8Array(ASN1_PREFIX_RSA_OAEP_SHA512_FOR_RSA_2048_ENCRYPTED_KEY.concat(ASN1_TAG_OCTET_STRING_RSA_2048_BIT_KEY));
    var ASN1_PREFIX_RSA_OAEP_4096 = new Uint8Array(ASN1_PREFIX_RSA_OAEP_SHA512_FOR_RSA_4096_ENCRYPTED_KEY.concat(ASN1_TAG_OCTET_STRING_RSA_4096_BIT_KEY));
    var APPROXIMATE_PUBLIC_KEY_LENGTH_4096 = 799;
    var CRYPTO_WORKER = _pathUtil.workerPath + "crypto-worker.js";

    var isNativeCryptoSupported = _browserUtility.default.isNativeCryptoSupported();

    var FORGE_ENGINE = _forgeRsaEngine.default;
    var RSA_ENGINE;

    function initializeNativeEngines() {
      RSA_ENGINE = _nativeRsaEngine.default;
    }

    if (isNativeCryptoSupported) {
      // If supported then by default enable native crypto
      initializeNativeEngines();
    } else {
      console.warn("Native cryptography is not supported by this browser, performance will suffer as a result.");
    }

    window.FTAPISecureMathRandom = function () {
      var array = new Uint32Array(2);

      _nativeRandomEngine.default.getRandomValues(array);

      var result = "0.";

      for (var i = 0; i < array.length; i++) {
        result += array[i];
      }

      return Number(result);
    };

    var asn1 = forge.asn1;
    var EXPECTED_ACCESSKEY_ASN_1_STRUCTURE = {
      name: 'KeyTransRecipientInfo',
      tagClass: asn1.Class.UNIVERSAL,
      type: asn1.Type.SEQUENCE,
      constructed: true,
      value: [{
        name: 'KeyTransRecipientInfo.version',
        tagClass: asn1.Class.UNIVERSAL,
        type: asn1.Type.INTEGER,
        constructed: false
      }, {
        name: 'KeyTransRecipientInfo.rid',
        tagClass: asn1.Class.CONTEXT_SPECIFIC,
        type: asn1.Type.NONE,
        constructed: false
      }, {
        name: 'KeyTransRecipientInfo.KeyEncryptionAlgorithmIdentifier',
        tagClass: asn1.Class.UNIVERSAL,
        type: asn1.Type.SEQUENCE,
        constructed: true,
        value: [{
          name: 'KeyTransRecipientInfo.KeyEncryptionAlgorithmIdentifier.AlgorithmIdentifier',
          tagClass: asn1.Class.UNIVERSAL,
          type: asn1.Type.OID,
          constructed: false
        }, {
          name: 'KeyTransRecipientInfo.KeyEncryptionAlgorithmIdentifier.parameters',
          tagClass: asn1.Class.UNIVERSAL,
          type: asn1.Type.SEQUENCE,
          constructed: true,
          optional: true,
          value: [{
            tagClass: asn1.Class.CONTEXT_SPECIFIC,
            type: asn1.Type.NONE,
            constructed: true,
            value: [{
              tagClass: asn1.Class.UNIVERSAL,
              type: asn1.Type.SEQUENCE,
              constructed: true,
              value: [{
                tagClass: asn1.Class.UNIVERSAL,
                type: asn1.Type.OID,
                constructed: false
              }, {
                tagClass: asn1.Class.UNIVERSAL,
                type: asn1.Type.NULL,
                constructed: false
              }]
            }]
          }]
        }]
      }, {
        name: 'KeyTransRecipientInfo.encryptedKey',
        tagClass: asn1.Class.UNIVERSAL,
        type: asn1.Type.OCTETSTRING,
        constructed: false
      }]
    };
    /**
     * Determines whether native crypto implementations will be used.
     */

    var useNative = isNativeCryptoSupported;
    /**
     * Global override for using native crypto. This may result in exceptions if the browser isn't actually supported. Note this can also be used to force use of pure JS implementations.
     *
     * @global
     * @param {Boolean} forceUseNative Whether or not native crypto should be forced
     */

    window.setNativeCrypto = function (forceUseNative) {
      if (forceUseNative) {
        initializeNativeEngines();
      }

      useNative = forceUseNative;
    };
    /**
     * Runnable for performing actions on the CryptoWorker thread. The runnable starts a CryptoWorker and performs the given action.
     * @param   {String} domain           Domain for action ('aes', 'rsa', 'encoding', etc.)
     * @param   {String} command          Method to be executed on the worker
     * @param   {Array}    commandArguments Arguments for the given method call as an array
     *
     * @returns {RSVP.Promise} Returns a promise that is fulfilled with the result of the command.
     */


    function CryptoRunnable(domain, command, commandArguments) {
      commandArguments.unshift(domain + "." + command);
      var promise = new Promise(function (resolve, reject) {
        var worker = new Worker(CRYPTO_WORKER);

        worker.onmessage = function (event) {
          resolve(event.data); // Kill thread

          worker.terminate();
        };

        worker.onerror = function (error) {
          console.error(error);
          reject(error);
        };

        worker.postMessage(commandArguments);
      });
      return promise;
    }

    var crypto = {
      /**
       * Performs the complete SecuPass activation process, including
       * generating an RSA key pair and encrypting the Private Key with
       * the SecuAESKey.
       *
       * @param secuPassKey
       * @param salt
       * @return promise fulfilled with Object: publicKey,
       * encryptedPrivateKey, secuPassKeyHash
       */
      registerSecuPass: function registerSecuPass(secuPassKey, salt) {
        var generationPromise;
        var promise = new Promise(function (resolve, reject) {
          generationPromise = crypto.generateRSAKeyPair();
          generationPromise.then(function (keyPair) {
            crypto.prepareSecuAESKey(secuPassKey, salt).then(function (secuAESKey) {
              crypto.encryptPrivateKey(keyPair.privateKey, secuAESKey).then(function (encryptedPrivateKey) {
                var result = {
                  publicKey: keyPair.publicKey,
                  encryptedPrivateKey: encryptedPrivateKey
                };
                resolve(result);
              }).catch(reject);
            }).catch(reject);
          }).catch(reject);
        }); // Expose key generation progress

        promise.progress = generationPromise.progress;
        return promise;
      },

      /**
       * Performs password stretching with a given SecuPass Key.
       *
       * @param password
       *            SecuPass Key as string
       * @param salt
       * @return secuAESKey as hex-encoded AES 256 key
       */
      prepareSecuAESKey: function prepareSecuAESKey(password, salt) {
        return new CryptoRunnable("password", "stretch", [password, salt, FTAPI_STANDARD_AES_KEY_LENGTH]);
      },

      /**
       * Encrypts Access Descriptor using RSA encryption.
       *
       * @param accessKey The plain access key as hex-encoded String
       * @param publicKeyPEM
       *            PEM-formatted Public Key
       * @return encryptedAccessDescriptor The access descriptor as KeyTransRecipientInfo (See RFC 5652) object
       *	                including the encrypted AES 256 Access Key
       */
      encryptAccessDescriptor: function encryptAccessDescriptor(accessKey, publicKeyPEM) {
        return new Promise(function (resolve, reject) {
          var accessDescriptorHexDecoded = _encodingUtility.default.fromHex(accessKey);

          crypto.cms.encryptAccessKey(accessDescriptorHexDecoded, publicKeyPEM).then(function (encryptedAccessDescriptor) {
            resolve(encryptedAccessDescriptor);
          }).catch(reject);
        });
      },

      /**
       * Decrypts Access Descriptor using the decrypted Private Key.
       *
       * @param encryptedAccessDescriptor The encrypted access descriptor string
       *									as it is stored in the access descriptor on server side.
       * @param privateKeyPEM
       *            decrypted Private Key PEM-formatted
       * @return decryptedAccessDescriptor as hex-encoded String
       */
      decryptAccessDescriptor: function decryptAccessDescriptor(encryptedAccessDescriptor, privateKeyPEM) {
        return new Promise(function (resolve, reject) {
          (0, _logger.d)(TAG, "Preparing to decrypt access descriptor");
          crypto.cms.decryptCMSFormattedAccessDescriptor(encryptedAccessDescriptor, privateKeyPEM).then(function (decryptedAccessDescriptorAsBinaryString) {
            (0, _logger.d)(TAG, "Decrypted access descriptor");

            var decryptedAccessDescriptor = _encodingUtility.default.toHex(decryptedAccessDescriptorAsBinaryString);

            resolve(decryptedAccessDescriptor);
          }).catch(reject);
        });
      },

      /**
       * Decrypts an encrypted message using the given AES-256 access key.
       *
       * @param {String} Encrypted message encoded Base64
       * @param {String} Access    Key hex encoded
       * @returns {String} Decrypted message
       */
      decryptMessage: function decryptMessage(base64EncodedMessage, accessKey) {
        if (base64EncodedMessage === undefined || base64EncodedMessage === null || base64EncodedMessage === "") {
          return new Promise(function (resolve) {
            resolve("");
          });
        }

        var messageDecoded = atob(base64EncodedMessage);

        var messageDecodedBytes = _encodingUtility.default._convertSafeStringToByteArray(messageDecoded);

        var promise = new Promise(function (resolve, reject) {
          crypto.aes.decrypt(messageDecodedBytes, accessKey).then(function (decryptedBytes) {
            var decryptedBody = _encodingUtility.default._convertByteArrayToUTF8String(decryptedBytes);

            resolve(decryptedBody);
          }).catch(reject);
        });
        return promise;
      },

      /**
       * Encrypts a message using the given AES-256 access key.
       *
       * @param {String} message as UTF-8 String
       * @param {String} Access Key hex encoded
       * @returns {String} encrypted message Base64 encoded
       */
      encryptMessage: function encryptMessage(message, accessKey) {
        var promise = new Promise(function (resolve, reject) {
          crypto.aes.encrypt(message, accessKey).then(function (encryptedBytes) {
            var encrypted = _encodingUtility.default.toBase64(encryptedBytes);

            resolve(encrypted);
          }).catch(reject);
        });
        return promise;
      },

      /**
       * Generates RSA Key Pair using the standard FTAPI RSA key length.
       *
       * @return promise fulfilled with KeyPairPEM Object
       */
      generateRSAKeyPair: function generateRSAKeyPair() {
        return crypto.rsa.generateKeyPair(FTAPI_STANDARD_RSA_KEY_LENGTH);
      },

      /**
       * Does the same as {@link #aes.decrypt()} except that it strips the version tag from the beginning.
       *
       * @param encryptedPrivateKey (with version tag)
       * @param secuAESKey
       * @return decrypted PEM-formatted private key
       */
      decryptPrivateKey: function decryptPrivateKey(encryptedPrivateKey, secuAESKey) {
        encryptedPrivateKey = encryptedPrivateKey.substring(SECUPASS_VERSION.length, encryptedPrivateKey.length);

        var encryptedPrivateKeyBytes = _encodingUtility.default.fromHex(encryptedPrivateKey);

        return crypto.aes.decrypt(encryptedPrivateKeyBytes, secuAESKey);
      },

      /**
       * Encrypts a PEM-formatted private key and automatically appends the secupass version prefix.
       *
       * @param   {String} pemFormattedPrivateKey PEM-formatted
       * @param   {String} secuAESKey
       * @returns {String} Hex-encoded, encrypted private key with SecuPass version prefix.
       */
      encryptPrivateKey: function encryptPrivateKey(pemFormattedPrivateKey, secuAESKey) {
        var pemFormattedPrivateKeyBytes = _encodingUtility.default._convertUTF8StringToByteArray(pemFormattedPrivateKey);

        return new Promise(function (resolve, reject) {
          crypto.aes.encrypt(pemFormattedPrivateKeyBytes, secuAESKey).then(function (encryptedPrivateKeyBytes) {
            var encryptedPrivateKey = _encodingUtility.default.toHex(encryptedPrivateKeyBytes);

            encryptedPrivateKey = SECUPASS_VERSION + encryptedPrivateKey;
            resolve(encryptedPrivateKey);
          }).catch(reject);
        });
      },
      cms: {
        /**
         * Encrypts the given plaintext key with given public key using the given
         * algorithm. The resulting format is a base64 encoded ASN.1 format as
         * defined in <a href="http://tools.ietf.org/html/rfc5652#section-6.2.1">RFC
         * 5652 - Section 6.2.1</a>
         *
         * @param   {byte array} plainAccessKeyBytes The plain access key.
         * @param   {String}   publicKey          The public key that should be used for
         *                                      encrypting the key
         * @returns {String} The base64 encoded KeyTransRecipientInfo (ASN.1) object that contain
         *                                        the encrypted access key
         */
        encryptAccessKey: function encryptAccessKey(plainAccessKeyBytes, publicKeyPEM) {
          var promise;

          if (FORGE_ENGINE) {
            promise = FORGE_ENGINE.encryptWithRSAOAEPUsingSHA512(plainAccessKeyBytes, publicKeyPEM);
          } else {
            var publicKeyAsDER = FORGE_ENGINE.convertPEMFormattedPublicKeyToDER(publicKeyPEM);
            promise = new Promise(function (resolve, reject) {
              var importPublicKeyPromise = RSA_ENGINE.importPublicKeyForRSAOAEPUsingSHA512(publicKeyAsDER);
              importPublicKeyPromise.then(function (importedPublicKey) {
                resolve(RSA_ENGINE.wrapAESCBCKeyWithRSAOAEPUsingSHA512(plainAccessKeyBytes, importedPublicKey));
              }).catch(reject);
            });
          }

          return promise.then(function (encryptedAccessKeyArrayBuffer) {
            var encryptedAccessKey = new Uint8Array(encryptedAccessKeyArrayBuffer);
            var bytes, base64Encoded;

            if (publicKeyPEM.length >= APPROXIMATE_PUBLIC_KEY_LENGTH_4096) {
              bytes = new Uint8Array(ASN1_PREFIX_RSA_OAEP_4096.byteLength + encryptedAccessKey.byteLength);
              bytes.set(new Uint8Array(ASN1_PREFIX_RSA_OAEP_4096), 0);
              bytes.set(new Uint8Array(encryptedAccessKey), ASN1_PREFIX_RSA_OAEP_4096.byteLength);
              base64Encoded = btoa(_encodingUtility.default._convertByteArrayToSafeString(bytes));
            } else {
              bytes = new Uint8Array(ASN1_PREFIX_RSA_OAEP_2048.byteLength + encryptedAccessKey.byteLength);
              bytes.set(new Uint8Array(ASN1_PREFIX_RSA_OAEP_2048), 0);
              bytes.set(new Uint8Array(encryptedAccessKey), ASN1_PREFIX_RSA_OAEP_2048.byteLength);
              base64Encoded = btoa(_encodingUtility.default._convertByteArrayToSafeString(bytes));
            }

            return base64Encoded;
          });
        },

        /**
         * Takes a base64 encoded KeyTransRecipientInfo (ASN.1) object and decrypts
         * the containing access key (also known as session key) with given private
         * key.
         *
         * NOTE: This method must also handle legacy keys.
         *
         * @param   {String} cmsFormattedEncryptedAccessDescriptor The base64 encoded encryption key as defined in
         *                                    <a href="http://tools.ietf.org/html/rfc5652#section-6.2.1">
         *                                    RFC 5652 - Section 6.2.1</a>.
         * @param   {String}   privateKey     The private key for decryption in PEM format.
         * @returns {[[Type]]} The decrypted access key.
         */
        decryptCMSFormattedAccessDescriptor: function decryptCMSFormattedAccessDescriptor(cmsFormattedEncryptedAccessDescriptor, privateKey) {
          (0, _logger.d)(TAG, "Preparing to decrypt CMS access descriptor");
          var accessDescriptorDecoded = atob(cmsFormattedEncryptedAccessDescriptor);
          var capture = {};
          var errors = [];
          var keyTransRecipientInfo;

          try {
            keyTransRecipientInfo = asn1.fromDer(accessDescriptorDecoded);
          } catch (err) {
            (0, _logger.e)(TAG, err);
          }

          if (keyTransRecipientInfo === undefined) {
            // If this is a legacy key asn1.fromDer throws an error
            return crypto.rsa.decryptPKCS1v15(cmsFormattedEncryptedAccessDescriptor, privateKey);
          }

          if (!asn1.validate(keyTransRecipientInfo, EXPECTED_ACCESSKEY_ASN_1_STRUCTURE, capture, errors)) {
            (0, _logger.e)(TAG, "ASN.1 structure is invalid:" + errors);
          }

          var encryptedAccessKey = keyTransRecipientInfo.value[3].value; // buffer of bytes in DER format

          var buffer = forge.util.createBuffer(encryptedAccessKey);
          var hexEncodedBuffer = buffer.toHex();

          var encryptedAccessKeyByteArray = _encodingUtility.default.fromHex(hexEncodedBuffer);

          return this._decryptAccessDescriptorBytes(encryptedAccessKeyByteArray, privateKey);
        },
        _decryptAccessDescriptorBytes: function _decryptAccessDescriptorBytes(encryptedAccessKeyByteArray, privateKeyPEM) {
          var promise; // Forge engine was initialised, so native crypto is not fully supported (RSA SHA-512)

          if (FORGE_ENGINE) {
            (0, _logger.d)(TAG, "Decrypting access descriptor with forge engine.");
            promise = FORGE_ENGINE.decryptWithRSAOAEPUsingSHA512(encryptedAccessKeyByteArray, privateKeyPEM);
          } else {
            (0, _logger.d)(TAG, "Decrypting access descriptor with native cryptor API.");
            var privateKeyAsDER = FORGE_ENGINE.convertPEMFormattedPrivateKeyToDER(privateKeyPEM);
            promise = new Promise(function (resolve, reject) {
              var importPrivateKeyPromise = RSA_ENGINE.importPrivateKeyForRSAOAEPUsingSHA512(privateKeyAsDER);
              importPrivateKeyPromise.then(function (importedPrivateKey) {
                var unwrapKeyPromise = RSA_ENGINE.unwrapAESCBCKeyWithRSAOAEPUsingSHA512(encryptedAccessKeyByteArray, importedPrivateKey);
                unwrapKeyPromise.then(function (unwrappedKeyAsCryptoKey) {
                  var exportKeyPromise = _nativeAesEngine.default.exportKeyAsJWK(unwrappedKeyAsCryptoKey);

                  exportKeyPromise.then(function (exportedKey) {
                    var base64EncodedExportedKeyBytes = _encodingUtility.default.convertBase64UrlToBase64(exportedKey.k);

                    var exportedKeyBytes = atob(base64EncodedExportedKeyBytes);

                    var exportedKeyBytesAsByteArray = _encodingUtility.default._convertSafeStringToByteArray(exportedKeyBytes);

                    resolve(exportedKeyBytesAsByteArray);
                  }).catch(reject);
                }).catch(reject);
              }).catch(reject);
            });
          }

          return promise;
        }
      },
      rsa: {
        /**
         * @deprecated Only available for legacy keys.
         */
        decryptPKCS1v15: function decryptPKCS1v15(data, privateKeyPEM) {
          return new CryptoRunnable("rsa", "decrypt", [data, privateKeyPEM]);
        },
        generateKeyPair: function generateKeyPair(keyLength) {
          var isFinished = false;
          var promise;

          var _step2; //Function which will be called for each progress step


          if (useNative) {
            promise = this._generateKeyPairForRSAOAEPUsingSHA512(keyLength);
            var progressStepDuration = 500;

            _step2 = function step() {
              incrementProgress(progressStepDuration, function () {
                if (!isFinished) {
                  setTimeout(_step2, progressStepDuration);
                }
              });
            };
          } else {
            promise = new Promise(function (resolve, reject) {
              var rsa = forge.pki.rsa;
              var state = rsa.createKeyPairGenerationState(keyLength, 0x10001);
              var progressStepDuration = 1000;

              _step2 = function _step() {
                // run for 100 ms
                if (!rsa.stepKeyPairGenerationState(state, progressStepDuration)) {
                  incrementProgress(progressStepDuration, function () {
                    setTimeout(_step2, 10);
                  });
                } else {
                  // progress finished
                  if (!state.keys) {
                    reject();
                  }

                  var keyPairPEM = {
                    publicKey: forge.pki.publicKeyToPem(state.keys.publicKey),
                    privateKey: forge.pki.privateKeyToPem(state.keys.privateKey)
                  };
                  resolve(keyPairPEM);
                }
              };
            });
          }

          setTimeout(_step2, 1);
          var self = this;
          var progressCompleted = 0;

          var totalProgress = this._estimateKeyGenerationDuration(keyLength);

          var progressTimeout;

          var progressCallback = function progressCallback(progress, finished) {
            if (finished) {
              finished();
            }
          };

          promise.then(function () {
            isFinished = true; // Kill progress

            clearTimeout(progressTimeout);

            if (progressCallback !== undefined) {
              progressCallback(100, function () {});
            }
          });

          promise.progress = function (callback) {
            progressCallback = callback;
          };

          function incrementProgress(progressStepDuration, finished) {
            progressCompleted += progressStepDuration;

            var progressPercentage = self._estimatedProgressBasedOnDuration(progressCompleted, totalProgress);

            if (progressPercentage < 95) {
              if (progressPercentage > 75) {
                totalProgress += progressStepDuration * 0.05;
              } else if (progressPercentage > 60) {
                totalProgress += progressStepDuration * 0.5;
              } else if (progressPercentage > 25) {
                totalProgress += progressStepDuration * 0.75;
              }
            }

            if (finished) {
              var finishedProgress = Math.ceil(progressPercentage);
              progressCallback(finishedProgress, finished);
            }
          }

          return promise;
        },
        _generateKeyPairForRSAOAEPUsingSHA512: function _generateKeyPairForRSAOAEPUsingSHA512(keyLength) {
          return new Promise(function (resolve, reject) {
            try {
              var generationPromise = _nativeRsaEngine.default.generateKeyPairForRSAOAEPUsingSHA512(keyLength);

              generationPromise.then(function (keyPair) {
                var expPrivProm = _nativeRsaEngine.default.exportPrivateKeyInPKCS8Format(keyPair.privateKey);

                var expPubProm = _nativeRsaEngine.default.exportPublicKeyInSPKIFormat(keyPair.publicKey);

                Promise.all([expPrivProm, expPubProm]).then(function (values) {
                  var privateKeyPEM = _forgeRsaEngine.default.convertBinaryPrivateKeyToPEM(values[0]);

                  var publicKeyPEM = _forgeRsaEngine.default.convertBinaryPublicKeyToPEM(values[1]);

                  var keyPair = {
                    publicKey: publicKeyPEM,
                    privateKey: privateKeyPEM
                  };
                  resolve(keyPair);
                }).catch(reject);
              }).catch(reject);
            } catch (e) {
              reject(e);
            }
          });
        },
        _estimateKeyGenerationDuration: function _estimateKeyGenerationDuration(keyLength) {
          if (keyLength === 4096) {
            if (useNative) {
              return EXPECTED_DURATION_RSA_KEY_GENERATION_4096_NATIVE;
            }

            return EXPECTED_DURATION_RSA_KEY_GENERATION_4096;
          } else if (keyLength === 2048) {
            if (useNative) {
              return EXPECTED_DURATION_RSA_KEY_GENERATION_2048_NATIVE;
            }

            return EXPECTED_DURATION_RSA_KEY_GENERATION_2048;
          }

          throw "Unsupported key length!";
        },
        _estimatedProgressBasedOnDuration: function _estimatedProgressBasedOnDuration(duration, expected) {
          var percentage = duration / expected;
          return Math.floor(percentage * 100);
        }
      },
      aes: {
        encrypt: function encrypt(data, key) {
          var iv = this.generateRandomIV();
          return new CryptoRunnable("aes", "encrypt", [data, key, iv]);
        },
        decrypt: function decrypt(encryptedData, key) {
          return new CryptoRunnable("aes", "decrypt", [encryptedData, key]);
        },
        generateRandomKey: function generateRandomKey() {
          return this._generateRandomBytes(32);
        },
        generateRandomIV: function generateRandomIV() {
          return this._generateRandomBytes(16);
        },
        _generateRandomBytes: function _generateRandomBytes(length) {
          var keyBytes = forge.random.getBytesSync(length);
          var keyHex = forge.util.binary.hex.encode(keyBytes);
          return keyHex;
        }
      },
      hashing: {
        md5: function md5(data) {
          return new CryptoRunnable("hashing", "md5", [data]);
        },
        sha512: function sha512(data) {
          return new CryptoRunnable("hashing", "sha512", [data]);
        }
      },
      password: {
        salt: function salt(cost) {
          if (typeof cost === "undefined") {
            cost = DEFAULT_BCRYPT_HASH_COST;
          }

          return new CryptoRunnable("password", "salt", [cost]);
        },
        hash: function hash(password, salt) {
          return new CryptoRunnable("password", "hash", [password, salt]);
        },
        verify: function verify(password, passwordHash) {
          return new CryptoRunnable("password", "verify", [password, passwordHash]);
        },
        generate: function generate(password, salt) {
          return new Promise(function (resolve, reject) {
            crypto.password.hash(password, salt).then(function (hash) {
              resolve(hash.substring(29, hash.length));
            }).catch(reject);
          });
        }
      },

      /**
       * Private method for testing purposes only
       * @param {Number} keyLength Default RSA key length
       */
      _setDefaultKeyLength: function _setDefaultKeyLength(keyLength) {
        FTAPI_STANDARD_RSA_KEY_LENGTH = keyLength;
      }
    };
    return crypto;
  }

  var _default = CryptoComposite;
  _exports.default = _default;
});