define("ftapi-core/composite/secu-pass-registration-composite", ["exports", "ftapi-core/crypto/crypto-utility", "ftapi-core/util/password-utility"], function (_exports, _cryptoUtility, _passwordUtility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Composite for registering SecuPass on a new user.
   * @param   {String} plainTextPassword
   * @param   {Object} user
   * @param   {Object} client
   */
  function SecuPassRegistrationComposite(plainTextPassword, user, client) {
    var self = this;
    var store = client.store;
    var secuPassGenerationSalt = user.get("secuPassGenerationSalt");
    var passwordRegex = client.config["ftapi.password.policy.regex"];

    this._registerPublicKey = function (publicKeyPEM, user) {
      var publicKey = store.createRecord("publicKey");
      publicKey.set("publicKey", publicKeyPEM);
      publicKey.set("userUuid", user.id);
      return publicKey.save();
    };

    this._registerPrivateKey = function (encryptedPrivateKey, user) {
      var privateKey = store.createRecord("privateKey");
      privateKey.set("encryptedPrivateKey", encryptedPrivateKey);
      privateKey.set("userUuid", user.id);
      return privateKey.save();
    };

    this.register = function (progressCallback) {
      var passwordValid = _passwordUtility.default.isPasswordValid(passwordRegex, plainTextPassword, user.get("email"));

      if (!passwordValid) {
        throw "This password is not allowed! Make sure password is valid which means validatePassword must return true!";
      }

      var secuPassPromise;
      var promise = new Promise(function (resolve, reject) {
        if (secuPassGenerationSalt === null) {
          reject("SecuPass Generation Salt is null!");
          return;
        }

        secuPassPromise = _cryptoUtility.default.registerSecuPass(plainTextPassword, secuPassGenerationSalt);
        secuPassPromise.progress(progressCallback);
        secuPassPromise.then(function (secuPass) {
          var publicKeyPromise = self._registerPublicKey(secuPass.publicKey, user);

          publicKeyPromise.then(function () {
            var privateKeyPromise = self._registerPrivateKey(secuPass.encryptedPrivateKey, user);

            privateKeyPromise.then(function () {
              resolve();
            }).catch(reject);
          }).catch(reject);
        }).catch(reject);
      });
      return promise;
    };
  }

  var _default = SecuPassRegistrationComposite;
  _exports.default = _default;
});