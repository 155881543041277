define("ftapi-core/util/string-utility", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Util class for string operations.
   *
   * @author Bernhard Foerster
   */
  var StringUtility = function () {
    var util = {
      concat: function concat(elements, separator) {
        var result;

        if (elements === null || elements === undefined) {
          result = "";
        } else {
          elements.forEach(function (email) {
            if (result === undefined) {
              result = email;
            } else {
              result += separator + email;
            }
          });
        }

        return result;
      },

      /**
       * Checks if a given string starts with a prefix.
       *
       * @param   {string} toCheck The string which should be checked.
       * @param   {string} prefix  The prefix the string should start with.
       * @returns {Boolean}  true if toCheck starts with prefix, else false.
       *                     If one of the arguments is null or undefined this
       *                     method returns false.
       */
      startsWith: function startsWith(toCheck, prefix) {
        if (toCheck === undefined || toCheck === null || prefix === undefined || prefix === null) {
          return false;
        }

        return toCheck.indexOf(prefix) === 0;
      },

      /**
      * Checks if a given string is null, undefined or empty.
      *
      * @param   {string} toCheck The string which should be checked.
      * @returns {Boolean}  true if toCheck is null, undefined or empty. Else false.
      */
      isEmptyOrNull: function isEmptyOrNull(toCheck) {
        return toCheck === undefined || toCheck === null || toCheck === "";
      },

      /**
       * Checks if a given string ends with a suffix.
       *
       * @param   {string} toCheck The string which should be checked.
       * @param   {string} suffix  The suffix the string should end with.
       * @returns {Boolean}  true if toCheck ends with suffix, else false.
       *                     If one of the arguments is null or undefined this
       *                     method returns false.
       */
      endsWith: function endsWith(toCheck, suffix) {
        if (toCheck === undefined || toCheck === null || suffix === undefined || suffix === null) {
          return false;
        }

        return toCheck.lastIndexOf(suffix) === toCheck.length - suffix.length;
      },
      createId: function createId(length) {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < length; i++) {
          text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
      }
    };
    return util;
  }();

  var _default = StringUtility;
  _exports.default = _default;
});