define("ftapi-core/models/folder", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Creates new Folder
   * 
   * @author Maximilian Meier
   * @returns {Folder} New Folder
   */
  var Folder = _emberData.default.Model.extend({
    uuid: _emberData.default.attr("string"),
    name: _emberData.default.attr("string"),
    encrypted: _emberData.default.attr("string")
  });

  var _default = Folder;
  _exports.default = _default;
});