define("ftapi-core/crypto/forge/forge-rsa-engine", ["exports", "ftapi-core/util/encoding-utility"], function (_exports, _encodingUtility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * RSA encryption methods using the digitalbazaar/forge API.
   *
   * @author Marco Wolff
   */
  var ForgeRSAEngine = function () {
    var forgeRSAEngine = {
      /**
       * Encrypts as array buffer given data using the given pem formatted public key using RSA PKCS1.
       * @param   {string} publicKeyAsPEM    PEM formatted public key
       * @param   {ByteArray or String} toEncrypt
       * @returns {string} RSA PKCS1 encrytped data, hex encoded
       */
      encryptWithRSAPKCS1: function encryptWithRSAPKCS1(publicKeyAsPEM, toEncrypt) {
        if (typeof toEncrypt === "string") {
          toEncrypt = _encodingUtility.default._convertUTF8StringToByteArray(toEncrypt);
        }

        var toEncryptBase64Encoded = forge.util.binary.base64.encode(toEncrypt);
        var forgePublicKey = forge.pki.publicKeyFromPem(publicKeyAsPEM);
        var encryptedData = forgePublicKey.encrypt(toEncryptBase64Encoded);
        var encryptedDataHexEncoded = forge.util.binary.hex.encode(encryptedData);
        return encryptedDataHexEncoded;
      },

      /**
       * Decrypts as array buffer given data using the given pem formatted private key using RSA PKCS1.
       * @param   {string} privateKeyAsPEM            PEM formatted private key
       * @param   {string} toDecrypt Hex encoded
       * @returns {ByteArray} RSA PKCS1 decrypted data
       */
      decryptWithRSAPKCS1: function decryptWithRSAPKCS1(privateKeyAsPEM, toDecrypt) {
        var forgePrivateKey = forge.pki.privateKeyFromPem(privateKeyAsPEM);
        var toDecryptHexDecoded = forge.util.binary.hex.decode(toDecrypt);
        var decryptedDataBase64Encoded = forgePrivateKey.decrypt(toDecryptHexDecoded);
        var decryptedData = forge.util.decode64(decryptedDataBase64Encoded);

        var decryptedDataAsByteArray = _encodingUtility.default._convertSafeStringToByteArray(decryptedData);

        return decryptedDataAsByteArray;
      },

      /**
       * Converts the given binary data representing a public key to the public key in PEM format.
       * @param   {ArrayBuffer} publicKeyAsBinaryData Public key as binary data
       * @returns {string} PEM formatted public key
       */
      convertBinaryPublicKeyToPEM: function convertBinaryPublicKeyToPEM(publicKeyAsBinaryData) {
        var buffer = new forge.util.ByteBuffer(publicKeyAsBinaryData);
        var asn1 = forge.asn1.fromDer(buffer);
        var publicKey = forge.pki.publicKeyFromAsn1(asn1);
        var publicKeyPEM = forge.pki.publicKeyToPem(publicKey);
        return publicKeyPEM;
      },

      /**
       * Converts the given PEM formatted public key to a DER encoded byte array.
       * @param   {string}   publicKeyAsPEM PEM formatted public key
       * @returns {ArrayBuffer} Public Key as DER encoded array buffer
       */
      convertPEMFormattedPublicKeyToDER: function convertPEMFormattedPublicKeyToDER(publicKeyAsPEM) {
        var forgePublicKey = forge.pki.publicKeyFromPem(publicKeyAsPEM);
        var publicKeyInfo = forge.pki.publicKeyToAsn1(forgePublicKey);
        var derEncoded = forge.asn1.toDer(publicKeyInfo);
        var derEncodedBytes = derEncoded.getBytes();

        var derEncodedArrayBuffer = _encodingUtility.default.bytesToArrayBuffer(derEncodedBytes);

        return derEncodedArrayBuffer;
      },

      /**
       * Converts the given PEM formatted private key to a DER encoded byte array.
       * @param   {string}   privateKeyAsPEM PEM formatted public key
       * @returns {ArrayBuffer} Private Key as DER encoded array buffer
       */
      convertPEMFormattedPrivateKeyToDER: function convertPEMFormattedPrivateKeyToDER(privateKeyAsPEM) {
        var forgePrivateKey = forge.pki.privateKeyFromPem(privateKeyAsPEM);
        var rsaPrivateKey = forge.pki.privateKeyToAsn1(forgePrivateKey);
        var privateKeyInfo = forge.pki.wrapRsaPrivateKey(rsaPrivateKey);
        var derEncodedBytes = forge.asn1.toDer(privateKeyInfo).getBytes();

        var derEncodedArrayBuffer = _encodingUtility.default.bytesToArrayBuffer(derEncodedBytes);

        return derEncodedArrayBuffer;
      },

      /**
       * Encrypts as array buffer given data using the given pem formatted public key using RSA OAEP with SHA-512.
       * @param   {ByteArray} toEncrypt
       * @param   {string} publicKeyAsPEM    PEM formatted public key
       * @returns {Promise} Resolves encrypted data
       */
      encryptWithRSAOAEPUsingSHA512: function encryptWithRSAOAEPUsingSHA512(toEncrypt, publicKeyPEM) {
        var _this = this;

        /* 
         * Getting the bytes into the right format for forge is rather difficult.
         * We create a ByteStringBuffer with the "hex" data and then get the bytes.
         * The bytes themselves are in Forge's own string format for binary data.
         */
        var bytesBuffer = forge.util.createBuffer(toEncrypt, 'hex');
        var bytesForge = bytesBuffer.getBytes();
        var promise = new Promise(function (resolve, reject) {
          try {
            var publicKey = forge.pki.publicKeyFromPem(publicKeyPEM);
            var encryptedByteString = publicKey.encrypt(bytesForge, 'RSA-OAEP', _this._getRSAOAEPUsingSHA512EncryptionSettings());
            var forgeStuff = forge.util.binary.hex.encode(encryptedByteString);

            var bytes = _encodingUtility.default.fromHex(forgeStuff);

            resolve(bytes);
          } catch (e) {
            reject(e);
          }
        });
        return promise;
      },

      /**
       * Decrypts as array buffer given data using the given pem formatted public key using RSA OAEP with SHA-512.
       * @param   {ByteArray} toDecrypt
       * @param   {string} publicKeyAsPEM    PEM formatted public key
       * @returns {Promise} Resolves decrypted data
       */
      decryptWithRSAOAEPUsingSHA512: function decryptWithRSAOAEPUsingSHA512(toDecrypt, privateKeyPEM) {
        var _this2 = this;

        var promise = new Promise(function (resolve, reject) {
          try {
            var privateKey = forge.pki.privateKeyFromPem(privateKeyPEM);
            var decryptedByteString = privateKey.decrypt(toDecrypt, 'RSA-OAEP', _this2._getRSAOAEPUsingSHA512EncryptionSettings());
            var forgeStuff = forge.util.binary.hex.encode(decryptedByteString);

            var bytes = _encodingUtility.default.fromHex(forgeStuff);

            resolve(bytes);
          } catch (e) {
            reject(e);
          }
        });
        return promise;
      },
      _getRSAOAEPUsingSHA512EncryptionSettings: function _getRSAOAEPUsingSHA512EncryptionSettings() {
        return {
          md: forge.md.sha512.create(),
          mgf1: {
            md: forge.md.sha512.create()
          }
        };
      },

      /**
       * Converts the given binary data representing a private key to the private key in PEM format.
       * @param   {ArrayBuffer} privateKeyAsBinaryData Private key as binary data
       * @returns {string} PEM formatted private key
       */
      convertBinaryPrivateKeyToPEM: function convertBinaryPrivateKeyToPEM(privateKeyAsBinaryData) {
        var buffer = new forge.util.ByteBuffer(privateKeyAsBinaryData);
        var asn1 = forge.asn1.fromDer(buffer);
        var privateKey = forge.pki.privateKeyFromAsn1(asn1);
        var privateKeyPEM = forge.pki.privateKeyToPem(privateKey);
        return privateKeyPEM;
      }
    };
    return forgeRSAEngine;
  }();

  var _default = ForgeRSAEngine;
  _exports.default = _default;
});