define("ftapi-core/crypto/native/native-rsa-engine", ["exports", "ftapi-core/logger", "ftapi-core/crypto/native/native-aes-engine", "ftapi-core/util/browser-utility"], function (_exports, _logger, _nativeAesEngine, _browserUtility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TAG = "NativeRSAEngine";
  /**
   * RSA encryption and key pair generating engine using the native window.crypto API. Shows a warning if the native crypto API is not available.
   * RSA PKCS#1 v1.5 not supported due to the fact that the window.crypto API does not provide such routines. For RSA PKCS#1 v1.5 en/decryption use the ForgeRSAEngine.
   *
   * @author Marco Wolff
   */

  var NativeRSAEngine = function () {
    var NATIVE_CRYPTO;

    if (_browserUtility.default.isNativeCryptoSupported()) {
      NATIVE_CRYPTO = window.crypto.subtle;
    } else {
      (0, _logger.w)(TAG, "Native Crypto API not available!");
    }

    var nativeRSAEngine = {
      /**
       * Generates a key pair for RSA-PKCS1-v1_5 with given length.
       *
       * @returns {Promise} A Promise which returns a CryptoKeyPair
       */
      generateKeyPairForRSAPKCS1: function generateKeyPairForRSAPKCS1(keyLength) {
        var algorithm = {
          name: "RSA-OAEP",
          modulusLength: keyLength,
          publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
          hash: {
            name: "SHA-256"
          }
        };
        var extractable = true;
        var keyUsages = ["encrypt", "decrypt", "wrapKey", "unwrapKey"];
        return this._generateKeyPair(algorithm, extractable, keyUsages);
      },

      /**
       * Generates a key pair for RSA-OAEP with given length.
       *
       * @returns {Promise} A Promise which returns a CryptoKeyPair
       */
      generateKeyPairForRSAOAEPUsingSHA512: function generateKeyPairForRSAOAEPUsingSHA512(keyLength) {
        var algorithm = {
          name: "RSA-OAEP",
          modulusLength: keyLength,
          publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
          hash: {
            name: "SHA-512"
          }
        };
        var extractable = true;
        var keyUsages = ["encrypt", "decrypt", "wrapKey", "unwrapKey"];
        return this._generateKeyPair(algorithm, extractable, keyUsages);
      },
      _generateKeyPair: function _generateKeyPair(algorithm, extractable, keyUsages) {
        var keyPairGenerationPromise = new Promise(function (resolve, reject) {
          NATIVE_CRYPTO.generateKey(algorithm, extractable, keyUsages).then(resolve).catch(reject);
        });
        return keyPairGenerationPromise;
      },

      /**
       * Exports the given private key.
       *
       * @param {CryptoKey} privateKey CryptoKey representing the private key
       *
       * @return {Promise} A Promise which returns the private key in PKCS8 format
       */
      exportPrivateKeyInPKCS8Format: function exportPrivateKeyInPKCS8Format(privateKey) {
        var format = "pkcs8";
        var exportPrivateKeyPromise = new Promise(function (resolve, reject) {
          NATIVE_CRYPTO.exportKey(format, privateKey).then(resolve).catch(reject);
        });
        return exportPrivateKeyPromise;
      },

      /**
       * Exports the given public key.
       *
       * @param   {CryptoKey} publicKey CryptoKey representing the public key
       *
       * @returns {Promise} A Promise which returns the public key in SPKI format
       */
      exportPublicKeyInSPKIFormat: function exportPublicKeyInSPKIFormat(publicKey) {
        var format = "spki";
        var exportPublicKeyPromise = new Promise(function (resolve, reject) {
          NATIVE_CRYPTO.exportKey(format, publicKey).then(resolve).catch(reject);
        });
        return exportPublicKeyPromise;
      },

      /**
       * Imports the DER encoded private key as a CryptoKey.
       * @param   {ArrayBuffer} privateKeyAsDER DER encoded private key as array buffer
       * @returns {Promise} Resolves private key as CryptoKey
       */
      importPrivateKeyForRSAOAEPUsingSHA512: function importPrivateKeyForRSAOAEPUsingSHA512(privateKeyAsDER) {
        var format = "pkcs8";
        var algorithm = {
          name: 'RSA-OAEP',
          hash: {
            name: "SHA-512"
          }
        };
        var extractable = false;
        var keyUsages = ['unwrapKey'];
        return this._importKey(format, privateKeyAsDER, algorithm, extractable, keyUsages);
      },

      /**
       * Imports the DER encoded public key as a CryptoKey.
       * @param   {ArrayBuffer} publicKeyAsDER DER encoded public key as array buffer
       * @returns {Promise} Resolves public key as CryptoKey
       */
      importPublicKeyForRSAOAEPUsingSHA512: function importPublicKeyForRSAOAEPUsingSHA512(publicKeyAsDER) {
        var format = "spki";
        var algorithm = {
          name: 'RSA-OAEP',
          hash: {
            name: "SHA-512"
          }
        };
        var extractable = false;
        var keyUsages = ['wrapKey'];
        return this._importKey(format, publicKeyAsDER, algorithm, extractable, keyUsages);
      },
      _importKey: function _importKey(format, keyData, algorithm, extractable, keyUsages) {
        return new Promise(function (resolve, reject) {
          NATIVE_CRYPTO.importKey(format, keyData, algorithm, extractable, keyUsages).then(resolve).catch(reject);
        });
      },

      /**
       * Wraps the as byte array given AES CBC key with RSA OAEP using SHA 512.
       * @param {ByteArray} dataBytes            AES CBC key as byte array
       * @param {CryptoKey} publicKeyAsCryptoKey Public key as CryptoKey
       * @returns {Promise} Resolves the wrapped AES CBC key as byte array
       */
      wrapAESCBCKeyWithRSAOAEPUsingSHA512: function wrapAESCBCKeyWithRSAOAEPUsingSHA512(dataBytes, publicKeyAsCryptoKey) {
        var format = "raw";
        var wrapAlgorithm = {
          name: publicKeyAsCryptoKey.algorithm.name,
          modulusLength: publicKeyAsCryptoKey.algorithm.modulusLength,
          publicExponent: publicKeyAsCryptoKey.algorithm.publicExponent,
          hash: publicKeyAsCryptoKey.algorithm.hash
        };
        return new Promise(function (resolve, reject) {
          var importKeyPromise = _nativeAesEngine.default.importKey256BitForAESCBC(dataBytes);

          importKeyPromise.then(function (keyAsCryptoKey) {
            var wrapKeyPromise = NATIVE_CRYPTO.wrapKey(format, keyAsCryptoKey, publicKeyAsCryptoKey, wrapAlgorithm);
            wrapKeyPromise.then(resolve).catch(reject);
          }).catch(reject);
        });
      },

      /**
       * Unwraps the as byte array given AES CBC key with RSA OAEP using SHA 512.
       * @param {ByteArray} dataBytes            AES CBC key as byte array
       * @param {CryptoKey} privateKeyAsCryptoKey Private key as CryptoKey
       * @returns {Promise} Resolves the unwrapped AES CBC key as CryptoKey
       */
      unwrapAESCBCKeyWithRSAOAEPUsingSHA512: function unwrapAESCBCKeyWithRSAOAEPUsingSHA512(dataBytes, privateKeyAsCryptoKey) {
        var format = "raw";
        var unwrapAlgorithm = {
          name: privateKeyAsCryptoKey.algorithm.name,
          modulusLength: privateKeyAsCryptoKey.algorithm.modulusLength,
          publicExponent: privateKeyAsCryptoKey.algorithm.publicExponent,
          hash: privateKeyAsCryptoKey.algorithm.hash
        };
        var unwrappedKeyAlgorithm = {
          name: "AES-CBC",
          length: 256
        };
        var extractable = true;
        var keyUsages = ['encrypt', 'decrypt'];
        return new Promise(function (resolve, reject) {
          NATIVE_CRYPTO.unwrapKey(format, dataBytes, privateKeyAsCryptoKey, unwrapAlgorithm, unwrappedKeyAlgorithm, extractable, keyUsages).then(resolve).catch(reject);
        });
      }
    };
    return nativeRSAEngine;
  }();

  var _default = NativeRSAEngine;
  _exports.default = _default;
});