define("ftapi-core/models/mail", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Creates a new mail object.
   *
   * @author Maximilian Meier
   * @returns {Mail} created Mail
   */
  var Mail = _emberData.default.Model.extend({
    uuid: _emberData.default.attr("string"),
    createdDate: _emberData.default.attr("number"),
    sentDate: _emberData.default.attr("number"),
    encrypted: _emberData.default.attr("boolean", {
      defaultValue: true
    }),
    subject: _emberData.default.attr("string"),
    recipients: _emberData.default.attr("string", {
      transient: true
    }),
    toRecipients: _emberData.default.attr("array"),
    ccRecipients: _emberData.default.attr("array"),
    bccRecipients: _emberData.default.attr("array"),
    body: _emberData.default.attr("string"),
    decryptedBody: _emberData.default.attr("string", {
      transient: true
    }),
    folderUuid: _emberData.default.attr("string"),
    from: _emberData.default.attr("string"),
    sent: _emberData.default.attr("boolean", {
      transient: true
    }),
    totalSize: _emberData.default.attr("number")
  });

  var _default = Mail;
  _exports.default = _default;
});