define("ftapi-core/util/array-utility", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Util class for array operations.
   *
   * @author Bernhard Foerster
   */
  var ArrayUtility = function () {
    var util = {
      remove: function remove(original, toRemove) {
        if (original === undefined || original === null) {
          return;
        }

        var index = original.indexOf(toRemove);

        if (index > -1) {
          original.splice(index, 1);
        }
      },
      removeAll: function removeAll(original, toRemoveArray) {
        if (original === undefined || original === null || toRemoveArray === undefined || toRemoveArray === null) {
          return;
        }

        toRemoveArray.forEach(function (toRemove) {
          util.remove(original, toRemove);
        });
      }
    };
    return util;
  }();

  var _default = ArrayUtility;
  _exports.default = _default;
});