define("ftapi-core/adapters/application", ["exports", "ember-data", "ftapi-core/util/email-utility", "ftapi-core/util/uuid-utility", "ftapi-core/util/url-utility"], function (_exports, _emberData, _emailUtility, _uuidUtility, _urlUtility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var FTAPI_HOST = _urlUtility.default.withoutTrailingSlash(_urlUtility.default.retrieveHostAddress(window.FTAPI.baseUrl));

  var FTAPI_CONTEXT_PATH = _urlUtility.default.withoutSurroundingSlashes(_urlUtility.default.retrieveContextPath(window.FTAPI.baseUrl));

  var FTAPI_API = "api/2";
  var FTAPI_NAMESPACE = FTAPI_CONTEXT_PATH === "" ? FTAPI_API : FTAPI_CONTEXT_PATH + "/" + FTAPI_API;
  /**
   * Custom RESTAdapter for the FTAPI REST API.
   *
   * @author Alexander Stonehouse
   */

  var FTAPIRESTAdapter = _emberData.default.RESTAdapter.extend({
    host: FTAPI_HOST,
    namespace: FTAPI_NAMESPACE,
    headers: function () {
      var client = this.client;
      return client.get("headers");
    }.property().volatile(),

    /**
     * Customised path for type implementation that doesn't pluralise
     *
     * @override
     */
    pathForType: function pathForType(type) {
      var resolvedType = type;

      if (type === "access-descriptor") {
        resolvedType = "key/access";
      } else if (type === "folder-child") {
        resolvedType = "children";
      }

      return resolvedType;
    },
    handleResponse: function handleResponse(status, headers, payload) {
      if (status > 299) {
        var metadata = null;

        if (payload && payload.error && payload.error.metadata) {
          metadata = payload.error.metadata;
        }

        var error = new _emberData.default.InvalidError([metadata]);
        error.responseJSON = payload;
        return error;
      }

      return this._super(status, headers, payload);
    },
    buildURL: function buildURL(type, id, snapshot, requestType) {
      var resolvedType = this.pathForType(type);
      var resultingUrl = this.host + "/";

      if (resolvedType === "folder-children") {
        resultingUrl += this.namespace + "/folder/" + id + "/children";
      } else if (resolvedType === "private-key" && (id === "self" || id === null)) {
        resultingUrl += this.namespace + "/key/private";
      } else if (resolvedType === "public-key") {
        var publicAPI = this.namespace + "/key/public";

        if (id === "self" || id === null) {
          resultingUrl += publicAPI;
        } else if (_emailUtility.default.isEmail(id)) {
          resultingUrl += publicAPI + "/email/" + id;
        } else if (_uuidUtility.default.isUuid(id)) {
          resultingUrl += publicAPI + "/uuid/" + id;
        } else {
          throw "Unsupported public key request! [" + requestType + ", " + id + "]";
        }
      } else if (resolvedType === "user" && (id === "self" || requestType === "updateRecord")) {
        resultingUrl += this.namespace + "/user";
      } else if (type === "accessDescriptor" || type === "access-descriptor") {
        if (requestType === "createRecord") {
          resultingUrl += this.namespace + "/" + resolvedType + "/" + snapshot.attr("accessibleUuid") + "/" + snapshot.attr("userUuid");
        } else if (requestType === "findAll") {
          resultingUrl += this.namespace + "/" + resolvedType + "/" + "pending";
        } else if (requestType === "findRecord") {
          resultingUrl = this._super(type, id);
        } else {
          throw "Unsupported access descriptor request! [" + requestType + "]";
        }
      } else if (type === "file" && requestType === "createRecord") {
        var folderUuid = snapshot.attr("folderUuid");
        resultingUrl += this.namespace + "/folder/" + folderUuid + "/file";
      } else if (resolvedType === "mail-status") {
        resultingUrl += this.namespace + "/mail/" + id + "/status";
      } else {
        resultingUrl = this._super(type, id);
      }

      return resultingUrl;
    }
  });

  var _default = FTAPIRESTAdapter;
  _exports.default = _default;
});