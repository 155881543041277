define("ftapi-core/util/url-utility", ["exports", "ftapi-core/util/string-utility"], function (_exports, _stringUtility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Util class for url functions.
   *
   * @author Bernhard Foerster
   */
  var URLUtility = function () {
    var util = {
      extractGetParams: function extractGetParams(queryString) {
        var indexOfQuestionMark = queryString.indexOf("?");

        if (indexOfQuestionMark > -1) {
          queryString = queryString.substring(indexOfQuestionMark + 1);
        }

        var result = {};
        var allParams = queryString.split("&");

        for (var i = 0; i < allParams.length; i++) {
          var param = allParams[i].split("=");
          result[param[0]] = param[1];
        }

        return result;
      },
      concatenateURIsWithSlash: function concatenateURIsWithSlash(uriOne, uriTwo) {
        var uriOneEndsWithSlash = _stringUtility.default.endsWith(uriOne, "/");

        var uriTwoStartsWithSlash = _stringUtility.default.startsWith(uriTwo, "/");

        var resultingURI;

        if (!uriOneEndsWithSlash && !uriTwoStartsWithSlash) {
          resultingURI = uriOne + "/" + uriTwo;
        } else if (uriOneEndsWithSlash && uriTwoStartsWithSlash) {
          resultingURI = uriOne + uriTwo.substr(1);
        } else {
          resultingURI = uriOne + uriTwo;
        }

        return resultingURI;
      },
      withoutTrailingSlash: function withoutTrailingSlash(urlString) {
        var urlStringEndsWithSlash = _stringUtility.default.endsWith(urlString, "/");

        var resultingUrl;

        if (urlStringEndsWithSlash) {
          resultingUrl = urlString.substring(0, urlString.length - 1);
        } else {
          resultingUrl = urlString;
        }

        return resultingUrl;
      },
      withoutSurroundingSlashes: function withoutSurroundingSlashes(urlString) {
        var resultingUrl = this.withoutLeadingSlash(urlString);
        resultingUrl = this.withoutTrailingSlash(resultingUrl);
        return resultingUrl;
      },
      withoutLeadingSlash: function withoutLeadingSlash(urlString) {
        var urlStringStartsWithSlash = _stringUtility.default.startsWith(urlString, "/");

        var resultingUrl;

        if (urlStringStartsWithSlash) {
          resultingUrl = urlString.substring(1);
        } else {
          resultingUrl = urlString;
        }

        return resultingUrl;
      },
      retrieveHostAddress: function retrieveHostAddress(urlString) {
        var hostRegex = /(https?:\/\/[^/]+)(?:(?=\/[^/]+)*)?/i;
        var matches = hostRegex.exec(urlString);

        if (matches && matches[1] !== undefined) {
          return matches[1];
        } else {
          return "";
        }
      },
      retrieveContextPath: function retrieveContextPath(urlString) {
        var contextPathRegex = /(?:https?:\/\/[^/]+)((?:\/(?:[-a-z0-9._~!$&'()*+,;=:@]|%[0-9]{2})+)+\/?)(?=.*)/i;
        var matches = contextPathRegex.exec(urlString);

        if (matches && matches[1] !== undefined) {
          return matches[1];
        } else {
          return "";
        }
      }
    };
    return util;
  }();

  var _default = URLUtility;
  _exports.default = _default;
});