define("ftapi-core/models/folder-children", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Creates new FolderChildren object.
   * 
   * @author Maximilian Meier
   * @returns {FolderChildren} New FolderChildren object
   */
  var FolderChildren = _emberData.default.Model.extend({
    uuid: _emberData.default.attr("string"),
    folders: _emberData.default.hasMany("folder"),
    files: _emberData.default.hasMany("file")
  });

  var _default = FolderChildren;
  _exports.default = _default;
});