define("ftapi-core/util/device-utility", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Util class for device information.
   *
   * @author Alexander Stonehouse
   */
  var DeviceUtility = function () {
    var CATEGORY_STRINGS = ["xs", "sm", "md", "lg"];
    var currentDeviceCategory = -1;
    var cache = false;
    var util = {
      /* Constants for size categories */
      CATEGORY_XS: 0,
      CATEGORY_SM: 1,
      CATEGORY_MD: 2,
      CATEGORY_LG: 3,
      deviceCategoryString: function deviceCategoryString() {
        return CATEGORY_STRINGS[this.deviceCategoryInt()];
      },
      deviceCategory: function deviceCategory() {
        if (currentDeviceCategory >= 0) {
          return currentDeviceCategory;
        }

        var docWidth = Ember.$(document).width();
        var category;

        if (docWidth >= 1200) {
          category = 3;
        } else if (docWidth >= 992) {
          category = 2;
        } else if (docWidth >= 768) {
          category = 1;
        } else {
          category = 0;
        }

        if (cache) {
          currentDeviceCategory = category;
        }

        return category;
      }
    };
    return util;
  }();

  var _default = DeviceUtility;
  _exports.default = _default;
});