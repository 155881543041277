define("ftapi-core/client-context", ["exports", "ftapi-core/crypto/crypto-utility", "ftapi-core/util/encoding-utility", "ftapi-core/util/url-utility", "ftapi-core/logger", "ftapi-core/util/assert-utility"], function (_exports, _cryptoUtility, _encodingUtility, _urlUtility, _logger, _assertUtility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TAG = "ClientContext";
  /**
   * Singleton representing global scope of the application. This is accessible through dependency injection (i.e. this.client)
   *
   * @author Alexander Stonehouse
   */

  var ClientContext = Ember.Controller.extend({
    _antiCSRFTokenRequestHeaderName: "X-CSRF-TOKEN",
    _shouldPreventAbort: false,
    // Authenticated means the user has been fully authenticated using his password
    authenticated: false,
    // Means the user is authenticated but using an authentication key and not his password.
    // This means that the user is not able to encrypt his private key since he didn't enter his password
    authenticatedByAuthKey: false,
    auth: "",
    config: null,
    serverInfo: null,
    warnBeforeTermination: false,
    encryptionPassword: "",
    _pemFormattedPrivateKey: "",
    _baseUrl: "",
    setBaseUrl: function setBaseUrl(baseUrl) {
      this._baseUrl = _urlUtility.default.withoutTrailingSlash(baseUrl);
    },
    getBaseUrl: function getBaseUrl() {
      return this._baseUrl;
    },
    getPrivateKey: function getPrivateKey() {
      var encryptionPassword = this.get("encryptionPassword");

      if (encryptionPassword.length > 0) {
        (0, _logger.d)(TAG, 'Preparing to decrypt private key');
        var store = this.store;
        var self = this;
        return new Promise(function (resolve, reject) {
          var pemFormattedPrivateKey = self.get("_pemFormattedPrivateKey");

          if (pemFormattedPrivateKey.length > 0) {
            resolve(pemFormattedPrivateKey);
            return;
          }

          var userPromise = store.findRecord("user", "self");
          var privateKeyPromise = store.findRecord("privateKey", "self");
          Promise.all([userPromise, privateKeyPromise]).then(function (values) {
            (0, _logger.d)(TAG, 'Loaded private key and user');
            var user = values[0];
            var privateKey = values[1];
            var salt = user.get("secuPassGenerationSalt");

            _cryptoUtility.default.prepareSecuAESKey(encryptionPassword, salt).then(function (secuAESKey) {
              (0, _logger.d)(TAG, 'AES Key prepared [' + secuAESKey + ']');
              var encryptedPrivateKey = privateKey.get("encryptedPrivateKey");

              _cryptoUtility.default.decryptPrivateKey(encryptedPrivateKey, secuAESKey).then(function (privateKeyBytes) {
                pemFormattedPrivateKey = _encodingUtility.default.bytesToString(privateKeyBytes);
                (0, _logger.d)(TAG, 'Successfully decrypted private key [' + pemFormattedPrivateKey.substring(0, 10) + '...]');
                self.set("_pemFormattedPrivateKey", pemFormattedPrivateKey);
                resolve(pemFormattedPrivateKey);
              }).catch(reject);
            }).catch(reject);
          }).catch(reject);
        });
      }

      throw "Encryption Password is empty! Cannot load and decrypt Private Key";
    },
    logoutUser: function logoutUser() {
      this.passwordManager.globalLogout(this._shouldPreventAbort);
    },
    preventAbort: function preventAbort(customMessage) {
      if (!this._shouldPreventAbort) {
        this._shouldPreventAbort = true;

        this._preventAbort(customMessage);

        this.passwordManager.preventGlobalLogout();
      }
    },
    allowAbort: function allowAbort() {
      if (this._shouldPreventAbort) {
        this._shouldPreventAbort = false;

        this._allowAbort();

        this.passwordManager.allowGlobalLogout();
      }
    },
    _preventAbort: function _preventAbort(customMessage) {
      var self = this;
      Ember.$(window).bind("beforeunload", function () {
        // Found this on stackoverflow and it relly works ;)
        // the first setTimeout just adds the second one to the
        // UI queue and after that return will be called.
        // After the return a dialog is shown to the user and
        // the UI is blocked until the user clicks a button. If
        // the user clicks abort the UI queue will be processed
        // and the second setTimeout gets called.
        setTimeout(function () {
          setTimeout(function () {
            self.preventAbort(customMessage);
          }, 500);
        }, 1);
        self.allowAbort();
        return customMessage;
      });
    },
    _allowAbort: function _allowAbort() {
      Ember.$(window).off("beforeunload");
    },
    passwordManager: null,
    keyStore: null,
    headers: {},
    addHeader: function addHeader(headerName, headerValue) {
      this.set("headers." + headerName, headerValue);
      var self = this;
      Ember.$.ajaxSetup({
        beforeSend: function beforeSend(xhr) {
          var headers = self.get("headers");

          for (var headerName in headers) {
            var headerValue = headers[headerName];
            xhr.setRequestHeader(headerName, headerValue);
          }
        }
      });
    },
    removeHeader: function removeHeader(headerName) {
      delete this.headers[headerName];
    },
    getAntiCSRFToken: function getAntiCSRFToken() {
      var headers = this.get("headers");
      return headers[this._antiCSRFTokenRequestHeaderName];
    },
    createBaseUrlSensitiveRequestUrl: function createBaseUrlSensitiveRequestUrl(requestTarget) {
      _assertUtility.default.isNotEmpty(this._baseUrl, "Base URL must be defined!");

      return _urlUtility.default.concatenateURIsWithSlash(this._baseUrl, requestTarget);
    }
  });
  var _default = ClientContext;
  _exports.default = _default;
});