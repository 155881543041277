define("ftapi-core/util/time-constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ONE_DAY = 86400000;
  /**
  *  Time constants in milliseconds.
  *
  *  @author Alexander Stonehouse
  */

  var _default = {
    ONE_DAY: ONE_DAY,
    ONE_WEEK: ONE_DAY * 7
  };
  _exports.default = _default;
});