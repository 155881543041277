define("ftapi-core/util/encoding-utility", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var EncodingUtility = function () {
    var encoding = {
      toHex: function toHex(data) {
        if (typeof data === "string") {
          data = encoding._convertUTF8StringToByteArray(data);
        }

        var hex = encoding._convertByteStringToHex(data);

        return hex;
      },

      /**
       * Converts hex-encoded String to ByteArray
       *
       * @param string
       *            hex-encoded
       * @return ByteArray representing data from hex String
       */
      fromHex: function fromHex(string) {
        var byteArray = encoding._convertHexToBytes(string);

        return byteArray;
      },

      /**
       * Converts ByteArray/String to Base64-encoded String. Strings are
       * always first converted to SafeString, to effectively escape any
       * special UTF-8 characters.
       *
       * @param data
       *            as UTF-8 String or ByteArray
       * @return Base64-encoded String
       */
      toBase64: function toBase64(data) {
        if (typeof data === "string") {
          data = encoding._convertUTF8StringToSafeString(data);
        } else if (data instanceof Uint8Array) {
          data = encoding._convertByteArrayToSafeString(data);
        } else {
          throw "Cannot currently convert non-string data";
        }

        var base64 = btoa(data);
        return base64;
      },

      /**
       * Converts Base64-encoded String to ByteArray. Base64 String is
       * internally decoded to a SafeString, which is then decoded back to
       * a UTF-8 String to restore consistent encoding.
       *
       * @param base64
       * @return ByteArray
       */
      fromBase64: function fromBase64(base64) {
        var safeString = atob(base64);

        var string = encoding._convertSafeStringToUTF8String(safeString);

        var byteArray = encoding._convertUTF8StringToByteArray(string);

        return byteArray;
      },

      /**
       * Adds the '=' padding to the base64url encoded string if necessary.
       * @param   {string} base64Url base64url encoded
       * @returns {string} padded base64url encoded
       */
      _addPaddingToBase64Url: function _addPaddingToBase64Url(base64Url) {
        var base64SegmentLength = 4;
        var remaindingLength = base64Url.length % base64SegmentLength;

        if (!remaindingLength) {
          /* 
           * The whole thing fits perfectly into
           * the base64 segments, nothing to do here!
           */
          return base64Url;
        }

        var paddingLength = base64SegmentLength - remaindingLength;
        var paddedBase64Url = base64Url;

        for (var i = 0; i < paddingLength; i++) {
          paddedBase64Url = paddedBase64Url + '=';
        }

        return paddedBase64Url;
      },

      /**
       * Converts the given base64url encoded to base64 encoded.
       * @param   {string} base64Url 
       * @returns {string} base64 encoded
       */
      convertBase64UrlToBase64: function convertBase64UrlToBase64(base64Url) {
        var base64 = encoding._addPaddingToBase64Url(base64Url).replace(/\-/g, '+').replace(/_/g, '/');

        return base64;
      },

      /**
       * Converts UTF-8 String to a SafeString. This is necessary because
       * standard UTF-8 can't be serialized to Base64 or Hex. UTF-8 must
       * always be converted to a SafeString to ensure consistent
       * serialization in comparison to Java etc.
       *
       * Note that the resulting String appears to lose encoding, but can
       * be successfully reencoded to UTF-8 with
       * {@link #_convertSafeStringToUTF8String}.
       */
      _convertUTF8StringToSafeString: function _convertUTF8StringToSafeString(str) {
        // First encode string to URI encoding, safely storing UTF-8
        // encoding
        var URIEncodedString = encodeURIComponent(str); // Convert this back, which initially destroys the proper UTF-8
        // encoding, but is now safe for serialization.

        var decodedSafeString = unescape(URIEncodedString);
        return decodedSafeString;
      },

      /**
       * Converts a ByteArray to a UTF-8 String
       *
       * @param bytes
       *            ByteArray
       * @return UTF-8 encoded String
       */
      bytesToString: function bytesToString(bytes) {
        return EncodingUtility._convertByteArrayToUTF8String(bytes);
      },

      /**
       * Converts ByteArray to a JS Object
       * @param   bytes ByteArray
       * @returns {Object} Data from byte array parsed on JS object
       */
      bytesToObject: function bytesToObject(bytes) {
        return JSON.parse(encoding.bytesToString(bytes));
      },
      bytesToArrayBuffer: function bytesToArrayBuffer(bytes) {
        var arrayBuffer = new ArrayBuffer(bytes.length);
        var dataView = new Uint8Array(arrayBuffer);

        for (var i = 0; i < bytes.length; ++i) {
          dataView[i] = bytes.charCodeAt(i);
        }

        return arrayBuffer;
      },

      /**
       * Convert a SafeString back to a UTF-8 String. This is necessary
       * after decoding from Hex or Base64. Restores all proper UTF-8
       * encoding.
       */
      _convertSafeStringToUTF8String: function _convertSafeStringToUTF8String(str) {
        var UTF8String;

        try {
          // Convert the SafeString back to URI encoding
          var URIEncodedString = escape(str); // Decode URI encoding back to standard UTF-8

          UTF8String = decodeURIComponent(URIEncodedString);
        } catch (error) {
          UTF8String = decodeURIComponent(str);
        }

        return UTF8String;
      },
      _convertUTF8StringToByteArray: function _convertUTF8StringToByteArray(str) {
        var safeString = encoding._convertUTF8StringToSafeString(str);

        return encoding._convertSafeStringToByteArray(safeString);
      },
      _convertSafeStringToByteArray: function _convertSafeStringToByteArray(safeString) {
        var result = new Uint8Array(safeString.length);

        for (var i = 0; i < safeString.length; i++) {
          result[i] = safeString.charCodeAt(i);
        }

        return result;
      },
      _convertByteArrayToUTF8String: function _convertByteArrayToUTF8String(byteArray) {
        var safeString = encoding._convertByteArrayToSafeString(byteArray);

        var string = encoding._convertSafeStringToUTF8String(safeString);

        return string;
      },
      _convertByteArrayToSafeString: function _convertByteArrayToSafeString(byteArray) {
        if (byteArray instanceof ArrayBuffer) {
          byteArray = new Uint8Array(byteArray);
        }

        var i = 0;
        var result = "";

        while (i < byteArray.byteLength) {
          result += String.fromCharCode(byteArray[i]);
          i++;
        }

        return result;
      },
      _convertByteStringToHex: function _convertByteStringToHex(byteString) {
        return forge.util.binary.hex.encode(byteString);
      },
      _convertHexToBytes: function _convertHexToBytes(hexString) {
        return forge.util.binary.hex.decode(hexString);
      }
    };
    return encoding;
  }();

  var _default = EncodingUtility;
  _exports.default = _default;
});