define("ftapi-core/util/local-password-exchange-manager", ["exports", "ftapi-core/util/cross-tab-event-manager"], function (_exports, _crossTabEventManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Manager for exchanging the user credentials between open tabs. This prevents the user from having to log in again in every new tab. This works using the {@link CrossTabEventManager}, which contacts other tabs through a password request event. After authentication, a listener is registered, so that it can respond to new tabs that are opened.
   *
   * @author Alexander Stonehouse
   */
  function LocalPasswordExchangeManager(client) {
    var self = this;
    var EVENT_PREFIX = "LocalPasswordExchange_";
    var PASSWORD_REQUEST_EVENT = EVENT_PREFIX + "PasswordRequestEvent";
    var PASSWORD_RESPONSE_EVENT = EVENT_PREFIX + "PasswordResponseEvent";
    var GLOBAL_LOGOUT_EVENT = EVENT_PREFIX + "GlobalLogoutEvent";
    var SHOULD_PREVENT_GLOBAL_LOGOUT_REQUEST_EVENT = EVENT_PREFIX + "ShouldPreventGlobalLogoutRequest";
    var SHOULD_PREVENT_GLOBAL_LOGOUT_RESPONSE_EVENT = EVENT_PREFIX + "ShouldPreventGlobalLogoutResponse";
    var PREVENT_GLOBAL_LOGOUT_EVENT = EVENT_PREFIX + "PreventGlobalLogout";
    var ALLOW_GLOBAL_LOGOUT_EVENT = EVENT_PREFIX + "AllowGlobalLogout";
    var preventLogoutInTabMessage = "global.logout.prevented.in.this.tab";
    var successCallback;
    var preventGlobalLogoutFromThisTab = false;
    var shouldPreventGlobalLogout = false;
    var responded = false;
    var credentials = {
      email: null,
      password: null,
      antiCSRFToken: null
    };
    this._eventManager = new _crossTabEventManager.default();

    this._onPreventGlobalLogout = function () {
      preventGlobalLogoutFromThisTab = true;
    };

    this._onAllowGlobalLogout = function () {
      preventGlobalLogoutFromThisTab = false;
    };

    this._onPasswordRequestReceived = function () {
      self._eventManager.publish(PASSWORD_RESPONSE_EVENT, credentials);
    };

    this._onPasswordResponse = function (data) {
      if (!responded) {
        responded = true;
        credentials = data;
        successCallback(credentials);

        self._eventManager.listen(PASSWORD_REQUEST_EVENT, self._onPasswordRequestReceived);
      }
    };

    this._onGlobalLogoutReceived = function (doNotPerformActualLogout) {
      if (!doNotPerformActualLogout && !preventGlobalLogoutFromThisTab) {
        client.addHeader("Authorization", "");
        Ember.$.ajax("/j_spring_security_logout", {
          type: "POST",
          async: false
        });
      }

      window.location.assign(window.location.origin + "?noReq=true");
    };

    this._onShouldPreventGlobalLogoutRequest = function () {
      self._eventManager.publish(SHOULD_PREVENT_GLOBAL_LOGOUT_RESPONSE_EVENT, shouldPreventGlobalLogout);
    };

    this._onShouldPreventGlobalLogoutResponse = function (value) {
      preventGlobalLogoutFromThisTab = value;
      shouldPreventGlobalLogout = preventGlobalLogoutFromThisTab;
    };
    /**
     * Requests password to all other open tabs. The Promise is only ever resolved if other tabs are actually open and authenticated, so it may be the case that the promise will never be fullfilled.
     *
     * @returns {Promise} Resolves with credentials object {email:,password:}
     */


    this.requestPassword = function () {
      var promise = new Promise(function (resolve) {
        successCallback = resolve;
      });
      self.addListeners();

      self._eventManager.publish(PASSWORD_REQUEST_EVENT, {});

      self._eventManager.publish(SHOULD_PREVENT_GLOBAL_LOGOUT_REQUEST_EVENT, {});

      return promise;
    };

    this.addListeners = function () {
      self._eventManager.listen(SHOULD_PREVENT_GLOBAL_LOGOUT_RESPONSE_EVENT, self._onShouldPreventGlobalLogoutResponse);

      self._eventManager.listen(PREVENT_GLOBAL_LOGOUT_EVENT, self._onPreventGlobalLogout);

      self._eventManager.listen(ALLOW_GLOBAL_LOGOUT_EVENT, self._onAllowGlobalLogout);

      self._eventManager.listen(GLOBAL_LOGOUT_EVENT, self._onGlobalLogoutReceived);

      self._eventManager.listen(PASSWORD_RESPONSE_EVENT, self._onPasswordResponse);
    };
    /**
     * Starts listening for other tabs requesting the user credentials. Note that this will happen automatically after a successful password request. This behaviour important to ensure the maanger still functions if the original tab where the user authenticated is closed, but other tabs that requested the password are still open.
     *
     * @param {String} email
     * @param {String} password
     */


    this.listen = function (email, password, antiCSRFToken) {
      credentials.email = email;
      credentials.password = password;
      credentials.antiCSRFToken = antiCSRFToken;

      self._eventManager.listen(PASSWORD_REQUEST_EVENT, self._onPasswordRequestReceived);
    };
    /**
     * Starts listening for other tabs requesting if they should prevent global logout.
     */


    this.listenForShouldPreventGlobalLogoutRequests = function () {
      self._eventManager.listen(SHOULD_PREVENT_GLOBAL_LOGOUT_REQUEST_EVENT, self._onShouldPreventGlobalLogoutRequest);
    };
    /**
     * Triggers the global logout which leads to the process that every tab who is listening is going to logout and reload the page.
     */


    this.globalLogout = function (doNotPerformActualLogout) {
      if (preventGlobalLogoutFromThisTab === true) {
        // This is triggered onunload, so we need to use the native alert dialog
        alert(preventLogoutInTabMessage);
        return;
      }

      self._eventManager.publish(GLOBAL_LOGOUT_EVENT, {});

      self._onGlobalLogoutReceived(doNotPerformActualLogout);
    };
    /**
     * Prevents the global logout for other tabs.
     */


    this.preventGlobalLogout = function () {
      shouldPreventGlobalLogout = true;

      self._eventManager.publish(PREVENT_GLOBAL_LOGOUT_EVENT, {});
    };
    /**
     * Allows the global logout for other tabs.
     */


    this.allowGlobalLogout = function () {
      shouldPreventGlobalLogout = false;

      self._eventManager.publish(ALLOW_GLOBAL_LOGOUT_EVENT, {});
    };
    /**
     * Updates the password which is exchanged between the tabs.
     * @param {String} password
     */


    this.updatePassword = function (password) {
      credentials.password = password;
    };
  }

  var _default = LocalPasswordExchangeManager;
  _exports.default = _default;
});