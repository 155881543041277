define("ftapi-core/models/access-descriptor", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Creates a new AccessDescriptor.
   * 
   * @author Maximilian Meier
   * @returns {AccessDescriptor}  New AccessDescriptor
   */
  var AccessDescriptor = _emberData.default.Model.extend({
    uuid: _emberData.default.attr("string"),
    publicKeyHash: _emberData.default.attr("string"),
    accessKey: _emberData.default.attr("string"),
    userUuid: _emberData.default.attr("string"),
    accessibleUuid: _emberData.default.attr("string")
  });

  var _default = AccessDescriptor;
  _exports.default = _default;
});