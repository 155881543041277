define("ftapi-core/util/password-utility", ["exports", "ftapi-core/util/regex-utility"], function (_exports, _regexUtility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Util class for password functions.
   *
   * @author Bernhard Foerster
   */
  var PasswordUtility = function () {
    var util = {
      isPasswordValid: function isPasswordValid(passwordRegex, plainTextPassword, userEmail) {
        var passwordIsValid = true;

        if (passwordRegex === undefined || passwordRegex === null || passwordRegex === "") {
          throw "Password regex must not be null or empty!";
        }

        passwordIsValid = _regexUtility.default.isMatch(plainTextPassword, passwordRegex);

        if (passwordIsValid) {
          passwordIsValid = userEmail.toUpperCase() !== plainTextPassword.toUpperCase();
        }

        return passwordIsValid;
      }
    };
    return util;
  }();

  var _default = PasswordUtility;
  _exports.default = _default;
});