define("ftapi-core/localizations/localization", ["exports", "ftapi-core/localizations/strings.de", "ftapi-core/localizations/strings.en"], function (_exports, _strings, _strings2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.msg = msg;
  _exports.setLocale = setLocale;
  _exports.setVariable = setVariable;
  _exports.default = _exports.shortLocale = _exports.locale = void 0;

  /**
   * Localization loader for the Delivery App.
   *
   * @author Alexander Stonehouse
   *
   * @param   {[[Type]]} i18n [[Description]]
   * @param   {[[Type]]} en   [[Description]]
   * @param   {[[Type]]} de   [[Description]]
   * @returns {[[Type]]} [[Description]]
   */
  var warningShown = false;
  var fallback = "en";
  var locale = navigator.language || navigator.browserLanguage;
  _exports.locale = locale;
  var shortLocale = locale.length >= 2 ? locale.substring(0, 2) : locale;
  _exports.shortLocale = shortLocale;
  var localizations = {
    de: i18n.create(_strings.default),
    en: i18n.create(_strings2.default)
  };
  var VARS = {};

  function setVariable(variable, value) {
    VARS["$" + variable] = value;
  }
  /**
   * Finds localized string based on the given key from available localizations. If the browser's current localization isn't supported, then a fallback will be used.
   * @param   {String} key for the given localization
   * @returns {String} Localized string
   */


  function msg(key) {
    var localized = null;

    for (var localization in localizations) {
      if (locale.indexOf(localization) !== -1) {
        localized = localizations[localization](key);
      }
    }

    if (localized === null) {
      localized = localizations[fallback](key);

      if (!warningShown) {
        console.warn("Locale [" + shortLocale + "] not supported, defaulting to [" + fallback + "]");
        warningShown = true;
      }
    }

    if (localized.indexOf("$") !== -1) {
      for (var variable in VARS) {
        var re = new RegExp("\\" + variable, 'g');
        localized = localized.replace(re, VARS[variable]);
      }
    }

    return localized;
  }
  /**
   * This method allows the automatically-detected locale to be overwritten for debugging or testing purposes.
   * @param {String} overrideLocale
   */


  function setLocale(overrideLocale) {
    _exports.locale = locale = overrideLocale;
    _exports.shortLocale = shortLocale = overrideLocale.substring(0, 2);
  } // Made global for debugging purposes


  window.setLocale = setLocale;
  var Localization = {
    msg: msg
  };
  var _default = Localization;
  _exports.default = _default;
});