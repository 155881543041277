define("ftapi-core/util/url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setAPILevel = setAPILevel;
  _exports.default = void 0;
  var API_LEVEL = 2;
  var API_PREFIX = "/api/";
  /**
   * Simply class for ensuring that the base URL is always pretended to ajax requests.
   *
   * @author Alexander Stonehouse
   *
   * @param {String} path for URL (only path, not fully qualified URL)
   * @param {Boolean} indicates whether API call (default is true)
   *
   * @return {String} fully qualified URL relevant to current context
   */

  function URL(path, apiCall) {
    var url = FTAPI.baseUrl;

    if (apiCall === undefined) {
      apiCall = true;
    }

    if (apiCall) {
      url += API_PREFIX + API_LEVEL + "/";
    }

    url += path;
    return url;
  }

  function setAPILevel(newAPILevel) {
    API_LEVEL = newAPILevel;
  }

  var _default = URL;
  _exports.default = _default;
});