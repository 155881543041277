define("ftapi-core/models/mail-status", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Object containing current state information relating to Mail
   *
   * @author Alexander Stonehouse
   * @returns {MailStatus}
   */
  var MailStatus = _emberData.default.Model.extend({
    uuid: _emberData.default.attr("string"),
    userUuid: _emberData.default.attr("string"),
    access: _emberData.default.attr("boolean", {
      defaultValue: false
    }),
    accessDeniedReason: _emberData.default.attr("string"),
    owner: _emberData.default.attr("boolean", {
      defaultValue: false
    })
  });

  var _default = MailStatus;
  _exports.default = _default;
});