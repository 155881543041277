define("ftapi-core/util/css-utility", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Util class for adding, deleting and modifying css rules
   *
   * @author Maximilian Meier
   **/
  var CSSUtility = function () {
    var deletedRules = 0;
    var sessionStorage = {};
    var customizeCss = {
      /**
       * Adds the given css Rule to the first stylesheet of the webpage.
       * @param {String} cssClass  The css class that the property belongs to
       * @param {String} property  the property that includes a value
       * @param {String} value     the value for the css class, like color
       * @param {String} extraTags can be null! represents tags like :hover, :active, ...
       */
      addRule: function addRule(cssClass, property, value, extraTags) {
        var styleList = document.styleSheets[0];

        if (styleList === undefined) {
          return;
        }

        var rule = cssClass + extraTags + "{ " + property + ": " + value + " !important;}";
        var rulePosition = styleList.length;
        styleList.insertRule(rule, rulePosition);
        sessionStorage[cssClass + extraTags + property] = rulePosition;
      },

      /**
       * Returns a promise that deletes the given css property. first it searches inside the sessionStorage. as a fallback it iterates over all css styles.
       * @param   {String} cssClass The given css class that should be deleted
       * @param   {String}   property The given property to find it in the sessionStorage
       * @returns {Promise}   Promise that resolve when finished
       */
      deleteRule: function deleteRule(cssClassWithExtraTags, property) {
        return new Promise(function (resolve) {
          var styleList = document.styleSheets[0];

          if (styleList === undefined) {
            return;
          }

          var storageItem = sessionStorage[cssClassWithExtraTags + property];

          if (storageItem !== undefined && storageItem !== null) {
            styleList.deleteRule(storageItem - deletedRules);
            deletedRules++;
            resolve();
          } else {
            var ii = 0;
            var cssRule;

            do {
              cssRule = styleList.cssRules[ii];

              if (cssRule !== undefined) {
                var cssSelectorText = cssRule.selectorText;

                if (cssSelectorText) {
                  var cssRuleArray = cssSelectorText.substring(0, cssSelectorText.indexOf(":")).split(".").sort();
                  var cssClassArray = cssClassWithExtraTags.substring(0, cssSelectorText.indexOf(":")).split(".").sort();

                  if (cssRuleArray.toString === cssClassArray.toString) {
                    styleList.deleteRule(ii);
                    deletedRules++;
                    resolve();
                  }
                }

                ii++;
              }
            } while (cssRule !== undefined);

            resolve();
          }
        });
      },

      /**
       * Updates the given css rule by deleting it and adding it again
       * @param {String} cssClass  The css class that the property belongs to
       * @param {String} property  the property that includes a value
       * @param {String} value     the value for the css class, like color
       * @param {String} extraTags can be null! represents tags like :hover, :active, ...
       */
      updateRule: function updateRule(cssClass, property, value, extraTags) {
        var self = this;
        this.deleteRule(cssClass + extraTags, property).then(function () {
          self.addRule(cssClass, property, value, extraTags);
        });
      }
    };
    return customizeCss;
  }();

  var _default = CSSUtility;
  _exports.default = _default;
});