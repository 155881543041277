define("ftapi-core/crypto/crypto-utility", ["exports", "ftapi-core/crypto/composite/st-crypto-composite", "ftapi-core/logger"], function (_exports, _stCryptoComposite, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CryptoUtilityConstructor = CryptoUtilityConstructor;
  _exports.default = void 0;
  var TAG = "CryptoUtility";
  /**
   * Collection of all cryptography functions/utilities for FTAPI's SecuPass
   * encryption suite.
   *
   * @author Alexander Stonehouse
   */

  function CryptoUtilityConstructor() {
    // Instantiate default composite
    var composite = new _stCryptoComposite.default();

    function run(method) {
      var args = Array.apply(null, arguments);
      args.shift();
      var context;
      var func = composite;
      var methodSignature = method.split(".");
      (0, _logger.i)(TAG, "Executing composite method '" + method + "'");

      for (var q = 0; q < methodSignature.length; q++) {
        var part = methodSignature[q];
        context = func;
        func = func[part];
      }

      if (func === undefined) {
        (0, _logger.e)(TAG, "Unsupported method '" + method + "' for composite!");
      }

      return func.apply(context, args);
    }

    var crypto = {
      /**
       * Performs the complete SecuPass activation process, including
       * generating an RSA key pair and encrypting the Private Key with
       * the SecuAESKey.
       *
       * @param secuPassKey
       * @param salt
       * @return promise fulfilled with Object: publicKey,
       * encryptedPrivateKey, secuPassKeyHash
       */
      registerSecuPass: function registerSecuPass(secuPassKey, salt) {
        return run("registerSecuPass", secuPassKey, salt);
      },

      /**
       * Performs password stretching with a given SecuPass Key.
       *
       * @param password
       *            SecuPass Key as string
       * @param salt
       * @return secuAESKey as hex-encoded AES 256 key
       */
      prepareSecuAESKey: function prepareSecuAESKey(password, salt) {
        return run("prepareSecuAESKey", password, salt);
      },

      /**
       * Encrypts Access Descriptor using RSA encryption.
       *
       * @param accessKey The plain access key as hex-encoded String
       * @param publicKeyPEM
       *            PEM-formatted Public Key
       * @return encryptedAccessDescriptor The access descriptor as KeyTransRecipientInfo (See RFC 5652) object
       *	                including the encrypted AES 256 Access Key
       */
      encryptAccessDescriptor: function encryptAccessDescriptor(accessKey, publicKeyPEM) {
        return run("encryptAccessDescriptor", accessKey, publicKeyPEM);
      },

      /**
       * Decrypts Access Descriptor using the decrypted Private Key.
       *
       * @param encryptedAccessDescriptor The encrypted access descriptor string
       *									as it is stored in the access descriptor on server side.
       * @param privateKeyPEM
       *            decrypted Private Key PEM-formatted
       * @return decryptedAccessDescriptor as hex-encoded String
       */
      decryptAccessDescriptor: function decryptAccessDescriptor(encryptedAccessDescriptor, privateKeyPEM) {
        return run("decryptAccessDescriptor", encryptedAccessDescriptor, privateKeyPEM);
      },

      /**
       * Decrypts an encrypted message using the given AES-256 access key.
       *
       * @param {String} Encrypted message encoded Base64
       * @param {String} Access    Key hex encoded
       * @returns {String} Decrypted message
       */
      decryptMessage: function decryptMessage(base64EncodedMessage, accessKey) {
        return run("decryptMessage", base64EncodedMessage, accessKey);
      },

      /**
       * Encrypts a message using the given AES-256 access key.
       *
       * @param {String} message as UTF-8 String
       * @param {String} Access Key hex encoded
       * @returns {String} encrypted message Base64 encoded
       */
      encryptMessage: function encryptMessage(message, accessKey) {
        return run("encryptMessage", message, accessKey);
      },

      /**
       * Generates RSA Key Pair using the standard FTAPI RSA key length.
       *
       * @return promise fulfilled with KeyPairPEM Object
       */
      generateRSAKeyPair: function generateRSAKeyPair() {
        return run("rsa.generateKeyPair");
      },

      /**
       * Does the same as {@link #aes.decrypt()} except that it strips the version tag from the beginning.
       *
       * @param encryptedPrivateKey (with version tag)
       * @param secuAESKey
       * @return decrypted PEM-formatted private key
       */
      decryptPrivateKey: function decryptPrivateKey(encryptedPrivateKey, secuAESKey) {
        return run("decryptPrivateKey", encryptedPrivateKey, secuAESKey);
      },

      /**
       * Encrypts a PEM-formatted private key and automatically appends the secupass version prefix.
       *
       * @param   {String} pemFormattedPrivateKey PEM-formatted
       * @param   {String} secuAESKey
       * @returns {String} Hex-encoded, encrypted private key with SecuPass version prefix.
       */
      encryptPrivateKey: function encryptPrivateKey(pemFormattedPrivateKey, secuAESKey) {
        return run("encryptPrivateKey", pemFormattedPrivateKey, secuAESKey);
      },
      cms: {
        /**
         * Encrypts the given plaintext key with given public key using the given
         * algorithm. The resulting format is a base64 encoded ASN.1 format as
         * defined in <a href="http://tools.ietf.org/html/rfc5652#section-6.2.1">RFC
         * 5652 - Section 6.2.1</a>
         *
         * @param   {byte array} plainAccessKeyBytes The plain access key.
         * @param   {String}   publicKey          The public key that should be used for
         *                                      encrypting the key
         * @returns {String} The base64 encoded KeyTransRecipientInfo (ASN.1) object that contain
         *                                        the encrypted access key
         */
        encryptAccessKey: function encryptAccessKey(plainAccessKeyBytes, publicKeyPEM) {
          return run("cms.encryptAccessKey", plainAccessKeyBytes, publicKeyPEM);
        },

        /**
         * Takes a base64 encoded KeyTransRecipientInfo (ASN.1) object and decrypts
         * the containing access key (also known as session key) with given private
         * key.
         *
         * NOTE: This method must also handle legacy keys.
         *
         * @param   {String} cmsFormattedEncryptedAccessDescriptor The base64 encoded encryption key as defined in
         *                                    <a href="http://tools.ietf.org/html/rfc5652#section-6.2.1">
         *                                    RFC 5652 - Section 6.2.1</a>.
         * @param   {String}   privateKey     The private key for decryption in PEM format.
         * @returns {[[Type]]} The decrypted access key.
         */
        decryptCMSFormattedAccessDescriptor: function decryptCMSFormattedAccessDescriptor(cmsFormattedEncryptedAccessDescriptor, privateKey) {
          return run("cms.decryptCMSFormattedAccessDescriptor", cmsFormattedEncryptedAccessDescriptor, privateKey);
        }
      },
      rsa: {
        /**
         * @deprecated Only available for legacy keys.
         */
        decryptPKCS1v15: function decryptPKCS1v15(data, privateKeyPEM) {
          return run("rsa.decryptPKCS1v15", data, privateKeyPEM);
        },
        generateKeyPair: function generateKeyPair(keyLength) {
          return run("rsa.generateKeyPair", keyLength);
        },
        decrypt: function decrypt(data, privateKeyAsPEM) {
          return run("rsa.decrypt", data, privateKeyAsPEM);
        },
        encrypt: function encrypt(data, publicKeyAsPEM) {
          return run("rsa.encrypt", data, publicKeyAsPEM);
        }
      },
      aes: {
        encrypt: function encrypt(data, key) {
          return run("aes.encrypt", data, key);
        },
        decrypt: function decrypt(dataAsArrayBuffer, key) {
          return run("aes.decrypt", dataAsArrayBuffer, key);
        },
        generateRandomKey: function generateRandomKey() {
          return run("aes.generateRandomKey");
        },
        generateRandomIV: function generateRandomIV() {
          return run("aes.generateRandomIV");
        }
      },
      hashing: {
        md5: function md5(data) {
          return run("hashing.md5", data);
        },
        sha512: function sha512(data) {
          return run("hashing.sha512", data);
        }
      },
      password: {
        salt: function salt(cost) {
          return run("password.salt", cost);
        },
        hash: function hash(password, salt) {
          return run("password.hash", password, salt);
        },
        verify: function verify(password, passwordHash) {
          return run("password.verify", password, passwordHash);
        },
        generate: function generate(password, salt) {
          return run("password.generate", password, salt);
        }
      },
      setCryptoComposite: function setCryptoComposite(newComposite) {
        composite = newComposite;
        (0, _logger.i)(TAG, "CrypoComposite replaced");
      }
    };
    /**
     * Global method for overriding composite used in cryptographic functions. This can be used to change the crypto standards used.
     * @param {Object} newComposite Any Class that complies with CryptoUtility interface
     */

    window.setCryptoComposite = crypto.setCryptoComposite;
    return crypto;
  }

  var GLOBAL_INSTANCE = new CryptoUtilityConstructor();
  var _default = GLOBAL_INSTANCE;
  _exports.default = _default;
});