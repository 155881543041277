define("ftapi-core/models/file", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Creates new File Object
   *
   * @author Maximilian Meier
   * @returns {File} New File Object
   */
  var FileNode = _emberData.default.Model.extend({
    uuid: _emberData.default.attr("string"),
    name: _emberData.default.attr("string"),
    lastModified: _emberData.default.attr("number"),
    transferLength: _emberData.default.attr("number"),
    totalSegments: _emberData.default.attr("number"),
    folderUuid: _emberData.default.attr("string", {
      transient: true
    })
  });

  var _default = FileNode;
  _exports.default = _default;
});