define("ftapi-core/crypto/native/native-aes-engine", ["exports", "ftapi-core/logger", "ftapi-core/util/browser-utility"], function (_exports, _logger, _browserUtility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TAG = "NativeAESEngine";
  /**
   * AES encryption and key generating engine using the native window.crypto API. Shows a warning if the native crypto API is not available.
   *
   * @author Marco Wolff
   */

  var NativeAESEngine = function () {
    var NATIVE_CRYPTO;

    if (_browserUtility.default.isNativeCryptoSupported()) {
      NATIVE_CRYPTO = window.crypto.subtle;
    } else {
      (0, _logger.w)(TAG, "Native Crypto API not available!");
    }

    var nativeAESEngine = {
      /**
       * Generates a key for AES CBC with a length of 256bit.
       *
       * @returns {Promise} Resolves the generated CryptoKey
       */
      generateKey256BitForAESCBC: function generateKey256BitForAESCBC() {
        var algorithm = {
          name: "AES-CBC",
          length: 256
        };
        var extractable = true;
        var keyUsages = ["encrypt", "decrypt"];
        var keyGenerationPromise = new Promise(function (resolve, reject) {
          NATIVE_CRYPTO.generateKey(algorithm, extractable, keyUsages).then(resolve).catch(reject);
        });
        return keyGenerationPromise;
      },

      /**
       * Encrypts given data using the given key and iv with AES CBC.
       *
       * @param {CryptoKey} Symmetric key for AES CBC
       * @param {Uint8Array} IV for AES CBC
       * @param {ArrayBuffer} Data that should be encrypted
       *
       * @returns {Promise} Resolves the array buffer containing the encrypted data
       */
      encryptWithAESCBC: function encryptWithAESCBC(key, iv, dataAsArrayBuffer) {
        var algorithm = {
          name: "AES-CBC",
          iv: iv
        };
        var encryptPromise = new Promise(function (resolve, reject) {
          NATIVE_CRYPTO.encrypt(algorithm, key, dataAsArrayBuffer).then(resolve).catch(reject);
        });
        return encryptPromise;
      },

      /**
       * Decrypts given AES CBC encrypted data using the given key and iv with AES CBC.
       *
       * @param {CryptoKey} Symmetric key for AES CBC
       * @param {Uint8Array} IV for AES CBC
       * @param {ArrayBuffer} AES CBC encrypted Data that should be decrypted
       *
       * @returns {Promise} Resolves the array buffer containing the decrypted data
       */
      decryptWithAESCBC: function decryptWithAESCBC(key, iv, encryptedDataAsArrayBuffer) {
        var algorithm = {
          name: "AES-CBC",
          iv: iv
        };
        var decryptPromise = new Promise(function (resolve, reject) {
          NATIVE_CRYPTO.decrypt(algorithm, key, encryptedDataAsArrayBuffer).then(resolve).catch(reject);
        });
        return decryptPromise;
      },

      /**
       * Imports the given raw bytes of the key and the IV as key for AES CBC.
       * @param   {Uint8Array} rawKey The raw bytes of the key.
       * @returns {Promise} Resolves the CryptoKey from given raw bytes of key and iv
       */
      importKey256BitForAESCBC: function importKey256BitForAESCBC(rawKey) {
        var format = "raw";
        var algorithm = {
          name: "AES-CBC",
          length: 256
        };
        var extractable = true;
        var keyUsages = ["encrypt", "decrypt"];
        var importKeyPromise = new Promise(function (resolve, reject) {
          NATIVE_CRYPTO.importKey(format, rawKey, algorithm, extractable, keyUsages).then(resolve).catch(reject);
        });
        return importKeyPromise;
      },

      /**
       * Derives a 256 bit AES key for AES CBC using the given masterKey and salt.
       * @param   {ArrayBuffer} masterKey Key which will be used to derive a key
       * @param   {ArrayBuffer} salt      Salt which will be used to derive a key
       * @returns {Promise} Resolves derived 256bit AES key meant for AES CBC
       */
      deriveKey256BitForAESCBCUsingPBKDF2: function deriveKey256BitForAESCBCUsingPBKDF2(masterKey, salt) {
        var algorithm = {
          name: "PBKDF2",
          salt: salt,
          iterations: 10000,
          hash: "SHA-1"
        };
        var derivedKeyAlgorithm = {
          name: "AES-CBC",
          length: 256
        };
        var extractable = true;
        var keyUsages = ["encrypt", "decrypt"];
        var deriveKeyPromise = new Promise(function (resolve, reject) {
          NATIVE_CRYPTO.deriveKey(algorithm, masterKey, derivedKeyAlgorithm, extractable, keyUsages).then(resolve).catch(reject);
        });
        return deriveKeyPromise;
      },

      /**
       * Imports the given raw input as a key for PBKDF2 key derivation.
       * @param   {Uint8Array} rawInput
       * @returns {Promise} Resolves the derived key as CryptoKey
       */
      importKeyForPBKDF2KeyDerivation: function importKeyForPBKDF2KeyDerivation(rawInput) {
        var format = "raw";
        var algorithm = {
          name: "PBKDF2"
        };
        var extractable = false;
        var keyUsages = ["deriveBits", "deriveKey"];
        var importKeyPromise = new Promise(function (resolve, reject) {
          NATIVE_CRYPTO.importKey(format, rawInput, algorithm, extractable, keyUsages).then(resolve).catch(reject);
        });
        return importKeyPromise;
      },

      /**
       * Exports the given CryptoKey as raw bytes.
       * @param   {CryptoKey} cryptoKey Whichs raw bytes will be exported
       * @returns {Promise} Resolves array buffer as JWK representation of CryptoKey
       */
      exportKeyAsJWK: function exportKeyAsJWK(cryptoKey) {
        var format = "jwk";
        var exportKeyPromise = new Promise(function (resolve, reject) {
          NATIVE_CRYPTO.exportKey(format, cryptoKey).then(resolve).catch(reject);
        });
        return exportKeyPromise;
      }
    };
    return nativeAESEngine;
  }();

  var _default = NativeAESEngine;
  _exports.default = _default;
});