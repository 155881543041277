define("ftapi-core/composite/mail-download-composite", ["exports", "ftapi-core/composite/file-downloading-composite", "ftapi-core/util/string-utility", "file-saver"], function (_exports, _fileDownloadingComposite, _stringUtility, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function MailDownloadComposite(mail, client) {
    var self = this;
    var files = mail.get("attachments");
    var fileComposites = [];
    var composites = [];
    var fileCount = mail.get("attachments.length");
    var fileProgress = {};
    this.progress = Ember.Object.create({
      total: 0,
      cancelled: false,
      data: null
    });

    var mainBoundary = _stringUtility.default.createId(8);

    var secondBoundary = _stringUtility.default.createId(8);

    var date = new Date(Number(mail.sentDate));
    var dateString = date.toUTCString();
    var emlContent = "MIME-Version: 1.0\n";
    emlContent += "Received: " + dateString + "\n";
    emlContent += "From: " + mail.from + "\n";
    emlContent += "To: " + mail.toRecipients.toString() + "\n";

    if (mail.ccRecipients) {
      emlContent += "CC: " + mail.ccRecipients.toString() + "\n";
    }

    if (mail.bccRecipients) {
      emlContent += "BCC: " + mail.bccRecipients.toString() + "\n";
    }

    emlContent += "Subject: " + mail.subject + "\n";
    emlContent += "Date: " + dateString + "\n";
    emlContent += "Content-Type: multipart/mixed; boundary=" + mainBoundary + "\n\n";
    emlContent += "--" + mainBoundary + "\n";
    emlContent += "Content-Type: multipart/alternative; boundary=" + secondBoundary + "\n\n";
    emlContent += "--" + secondBoundary + "\n";
    emlContent += "Content-Type: text/plain; charset=UTF-8\n\n";
    emlContent += mail.decryptedBody + "\n\n";
    emlContent += "--" + secondBoundary + "\n";
    emlContent += "Content-Type: text/plain; charset=UTF-8\n\n";
    emlContent += "" + mail.decryptedBody + "\n\n";
    emlContent += "--" + secondBoundary + "--\n";

    this._calculateTotalProgress = function () {
      var total = 0;
      Ember.$.each(fileProgress, function (i, value) {
        total += value;
      });
      self.progress.set("total", total * 0.9 / fileCount);
    };

    this.execute = function () {
      return new Promise(function (resolve, reject) {
        var emlPromise = new Promise(function (resolve) {
          var fileEmlContent = "";
          var MIME_TYPES = {
            pdf: "application/pdf",
            bmp: "image/bmp",
            png: "image/png",
            css: "text/plain",
            gif: "image/gif",
            html: "text/html",
            jpg: "image/jpeg",
            jpeg: "image/jpeg",
            js: "text/plain",
            mp3: "audio/mpeg",
            mp4: "video/mp4",
            mpeg: "video/mpeg",
            rtf: "application/rtf",
            svg: "image/svg+xml",
            tiff: "image/tiff",
            txt: "text/plain",
            xml: "text/plain",
            zip: "application/zip",
            doc: "application/msword",
            docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            dot: "application/msword",
            dotx: "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
            xls: "application/excel",
            xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ppt: "application/mspowerpoint",
            pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            numbers: "application/vnd.apple.numbers",
            pages: "application/vnd.apple.pages",
            keynote: "application/vnd.apple.keynote"
          };
          var fileCounter = 0;
          files.forEach(function (file) {
            var formattedFileName = file.get("name");
            var formattedFileType;

            if (formattedFileName.lastIndexOf(".")) {
              formattedFileType = formattedFileName.substring(formattedFileName.lastIndexOf(".") + 1);
            } else {
              formattedFileType = formattedFileName;
            }

            var mimeType;
            mimeType = MIME_TYPES[formattedFileType];

            if (!mimeType) {
              mimeType = "application/octet-stream";
            }

            var composite = new _fileDownloadingComposite.default(client, file);
            composite.progress.addObserver("total", function () {
              fileProgress[file.get("id")] = composite.progress.total;

              self._calculateTotalProgress();
            });
            composites.push(composite);
            fileComposites.push(composite.load().then(function (file) {
              return [file, formattedFileName, mimeType];
            }));
          });
          Promise.all(fileComposites).then(function (values) {
            values.forEach(function (downloadedFile) {
              var reader = new FileReader();
              reader.readAsDataURL(downloadedFile[0]);

              reader.onloadend = function () {
                fileCounter++;
                var fileBase64 = reader.result;
                fileBase64 = fileBase64.substring(fileBase64.indexOf(","), fileBase64.length);
                fileEmlContent += "--" + mainBoundary + "\n";
                fileEmlContent += "Content-Type: " + downloadedFile[2] + "; name=\"" + downloadedFile[1] + "\"\n";
                fileEmlContent += "Content-Disposition: attachment; filename: \"" + downloadedFile[1] + "\"\n";
                fileEmlContent += "Content-Transfer-Encoding: base64\n\n";
                fileEmlContent += fileBase64 + "\n\n\n";

                if (fileCount === fileCounter) {
                  resolve(fileEmlContent);
                }
              };
            });
          }).catch(function (error) {
            if (error !== "mail.view.download.error.deleted") {
              reject(error);
            } else {
              resolve();
            }
          });

          if (fileCount === fileCounter) {
            resolve(fileEmlContent);
          }
        });
        emlPromise.then(function (fileEmlContent) {
          if (fileEmlContent) {
            emlContent += fileEmlContent;
          }

          emlContent += "--" + mainBoundary + "--\n";
          var blob = new Blob([emlContent], {
            type: "text/plain"
          });
          self.progress.set("total", 1);

          if (!self.progress.get("cancelled")) {
            var timeSaveName = date.toLocaleDateString().replace(/\./g, '-');
            var subjectSaveName = mail.subject.replace(/ /g, '-').replace(/[^\w\s]/gi, '');

            _fileSaver.default.saveAs(blob, "Secure-E-Mail_" + timeSaveName + "_" + mail.from + "_" + subjectSaveName + ".eml");
          }

          resolve();
        }).catch(reject);
      });
    };

    this.cancel = function () {
      self.progress.set("cancelled", true);
      composites.forEach(function (composite) {
        composite.cancel();
      });
    };
  }

  var _default = MailDownloadComposite;
  _exports.default = _default;
});