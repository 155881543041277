define("ftapi-core/util/cross-tab-event-manager", ["exports", "ftapi-core/util/string-utility", "ftapi-core/util/local-storage"], function (_exports, _stringUtility, _localStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Class for passing events between different tabs. This is done using local storage.
   *
   * @author Alexander Stonehouse
   */
  function CrossTabEventManager() {
    var self = this;
    var STORAGE_IDENTIFIER = "FTAPICrossTabEventManager-";
    var idLength = 14; // Listeners are stored here ordered by event type

    var eventListeners = {}; // Indicates whether the events should be fired across tabs or only locally. This is mainly for testing purposes.

    this.local = false;
    this.tabIdentifier = Math.floor(Date.now() / 1000) + "_";
    /**
     * Handles firing of events. Checks that the event key is valid and then finds appropriate listeners.
     * @param {StorageEvent} event
     */

    this._onEvent = function (event) {
      if (event.newValue !== null) {
        if (!self.local && _stringUtility.default.startsWith(event.key, self.tabIdentifier)) {
          return;
        }

        var eventPrefix = self.tabIdentifier + STORAGE_IDENTIFIER;

        if (!self.local && _stringUtility.default.startsWith(event.key, eventPrefix)) {
          return;
        }

        var eventType = event.key.substring(eventPrefix.length + idLength, event.key.length);
        var newEventValue = event.newValue;
        var parsedData = {}; // IE gets an pars error if not doing it this way

        if (newEventValue !== "") {
          parsedData = JSON.parse(newEventValue);
        }

        var listeners = eventListeners[eventType];

        if (listeners === undefined) {
          return;
        }

        for (var i = 0; i < listeners.length; i++) {
          listeners[i].callback(parsedData);
        }
      }
    };
    /**
     * Used to publish events within the same tab, effectively simulating a storage event
     * @param {String} key  for storage
     * @param {Object} data Event data
     */


    this._publishLocally = function (key, data) {
      var event = {
        key: key,
        newValue: data
      };

      self._onEvent(event);
    };
    /**
     * Adds an event callback to the given event type
     * @param {String} eventType Unique identifier for the event based on event
     * @param {Function} callback  Fired when event is published
     */


    this.listen = function (eventType, callback) {
      if (eventListeners[eventType] === undefined) {
        eventListeners[eventType] = [];
      }

      eventListeners[eventType].push({
        callback: callback
      });
    };
    /**
     * Publishes event with the given data. Data must not be null, but can be any kind of object otherwise. Depending on the local property, events will be fired using localStorage or locally within the manager.
     * @param {String} event Unique identifier for event type
     * @param {Object} data  to be passed to relevant event listeners (will be stringified)
     */


    this.publish = function (event, data) {
      var eventId = new Date().getTime().toString() + "-";
      var storageKey = self.tabIdentifier + STORAGE_IDENTIFIER + eventId + event;
      var storageData = {};

      if (data !== "") {
        storageData = JSON.stringify(data);
      }

      if (self.local) {
        self._publishLocally(storageKey, storageData);
      } else {
        _localStorage.default.setItem(storageKey, storageData);

        _localStorage.default.removeItem(storageKey);
      }
    };

    this.removeListeners = function () {
      window.removeEventListener('storage', self._onEvent);
    }; // Add event listener for global (i.e. cross-tab) events


    window.addEventListener('storage', this._onEvent, false);
  }

  var _default = CrossTabEventManager;
  _exports.default = _default;
});