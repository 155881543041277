define("ftapi-core/util/regex-utility", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Util class for regex functions.
   *
   * @author Bernhard Foerster
   */
  var RegexUtility = function () {
    var util = {
      isMatch: function isMatch(toCheck, regex) {
        if (toCheck === undefined || toCheck === null) {
          return false;
        }

        if (regex === undefined || regex === null) {
          return false;
        }

        var match = toCheck.match(regex);

        if (match === undefined || match === null) {
          return false;
        }

        return toCheck === match[0];
      }
    };
    return util;
  }();

  var _default = RegexUtility;
  _exports.default = _default;
});