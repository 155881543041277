define("ftapi-core/localizations/strings.de", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "values": {}
  };
  _exports.default = _default;
});