define("ftapi-core/composite/user-invite-composite", ["exports", "ftapi-core/util/email-utility", "ftapi-core/localizations/localization"], function (_exports, _emailUtility, _localization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function UserInviteComposite(userAddressList) {
    var self = this;
    self.post = Ember.$.post;

    self._inviteUser = function (address) {
      return new Promise(function (resolve, reject) {
        var userInviteUrl = "/api/2/user/invite";
        var requestTarget = self.client.createBaseUrlSensitiveRequestUrl(userInviteUrl);
        Ember.$.ajax({
          url: requestTarget,
          type: "POST",
          dataType: "json",
          data: JSON.stringify({
            user: {
              email: address,
              language: _localization.shortLocale
            }
          }),
          success: function success() {
            resolve(address);
          },
          error: function error() {
            reject(address);
          }
        });
      });
    };
    /**
     * Extracts email addresses from "TO" field.
     *
     * @param  s "TO" field
     * @return an array of extracted email addresses
     */


    this._extractEmails = function (s) {
      return _emailUtility.default.isEmail(s);
    };

    self.inviteAllUsers = function () {
      return new Promise(function (resolve, reject) {
        var addressList = [];

        if (Ember.$.isArray(userAddressList)) {
          addressList = userAddressList;
        } else {
          addressList = _emailUtility.default.extractEmails(userAddressList);
        }

        if (Ember.$.isArray(addressList) && addressList.length >= 1) {
          var invitePromises = [];
          addressList.forEach(function (address) {
            invitePromises.push(self._inviteUser(address));
          });
          Ember.RSVP.allSettled(invitePromises).then(function (allPromises) {
            var rejectedAddresses = [];
            var invitedAddresses = [];
            allPromises.forEach(function (promise) {
              var state = promise.state;

              if (state === "rejected") {
                rejectedAddresses.push(promise.reason);
              } else if (state === "fulfilled") {
                invitedAddresses.push(promise.value);
              }
            });

            if (rejectedAddresses.length === 0) {
              resolve();
            } else {
              var error = {
                rejectedAddresses: rejectedAddresses,
                invitedAddresses: invitedAddresses
              };
              reject(error);
            }
          }).catch(reject);
        } else {
          console.error("No valid email adress!");
          reject();
        }
      });
    };
  }

  var _default = UserInviteComposite;
  _exports.default = _default;
});