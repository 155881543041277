define("ftapi-core/models/public-key", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Creates new PublicKey object
   * 
   * @author Maximilian Meier
   * @returns {PublicKey} New PublicKey
   */
  var PublicKey = _emberData.default.Model.extend({
    uuid: _emberData.default.attr("string"),
    userUuid: _emberData.default.attr("string"),
    publicKey: _emberData.default.attr("string"),
    publicKeyHash: _emberData.default.attr("string")
  });

  var _default = PublicKey;
  _exports.default = _default;
});