define("ftapi-core/app", ["exports", "ftapi-core/resolver", "ember-load-initializers", "ftapi-core/config/environment"], function (_exports, _resolver, _emberLoadInitializers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var App;
  App = Ember.Application.extend({
    rootElement: "#ember-wrapper",
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  (function setupAjax() {
    Ember.run.later(function () {
      if (typeof ANTICSRF_PARAM !== "undefined") {
        Ember.$.ajaxSetup({
          contentType: "application/json; charset=utf-8",
          headers: {
            "anticsrfparam": ANTICSRF_PARAM
          }
        });
      } else {
        setupAjax();
      }
    });
  })();

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});