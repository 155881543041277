define("ftapi-core/util/email-utility", ["exports", "ftapi-core/util/regex-utility"], function (_exports, _regexUtility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Util class for email functions. Returns the result as an array.
   *
   * @author Bernhard Foerster
   *
   */
  var EmailUtility = function () {
    var util = {
      isEmail: function isEmail(s) {
        return _regexUtility.default.isMatch(s, /[a-z0-9\+\.\_\%\-\+]{1,256}\@[a-z0-9][a-z0-9\-]{0,64}(\.[a-z0-9][a-z0-9\-]{0,25})+/i);
      },
      extractEmails: function extractEmails(s) {
        var result = [];

        if (s === undefined || s === null || s === "") {
          return result;
        }

        s = s.replace(/;/g, ",");
        var splitted = s.split(",");
        splitted.forEach(function (email) {
          var trimmedEmail = email.trim();

          if (trimmedEmail !== "") {
            result.push(trimmedEmail);
          }
        });
        return result;
      },
      deleteEmailAddres: function deleteEmailAddres(emailArray, emailAddresToDelete) {
        var result = Ember.$.grep(emailArray, function (emailAddres) {
          if (emailAddres !== emailAddresToDelete) {
            return emailAddres;
          }
        });
        return result;
      }
    };
    return util;
  }();

  var _default = EmailUtility;
  _exports.default = _default;
});