define("ftapi-core/application/route", ["exports", "ftapi-core/util/key-store"], function (_exports, _keyStore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  if (window.FTAPI === undefined) {
    window.FTAPI = {};
  }

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      if (this.client.store === null) {
        this.client.store = this.store;
      }

      if (this.client.keyStore === null) {
        this.client.keyStore = new _keyStore.default(this.client);
      }

      window.FTAPI.client = this.client;
      this.client.setBaseUrl(window.FTAPI.baseUrl);
    }
  });

  _exports.default = _default;
});