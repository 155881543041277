define("ftapi-core/path-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.staticPath = _exports.workerPath = _exports.assetPath = _exports.resourcePath = _exports.appPath = void 0;
  var staticPath = "/static/";
  _exports.staticPath = staticPath;
  var appPath = "/static/";
  _exports.appPath = appPath;
  var resourcePath = staticPath + "resources/";
  _exports.resourcePath = resourcePath;
  var assetPath = "/assets/";
  _exports.assetPath = assetPath;
  var workerPath = assetPath + "workers/";
  _exports.workerPath = workerPath;
  var _default = staticPath;
  _exports.default = _default;
});