define("ftapi-core/util/local-storage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var LocalStorage = function () {
    var storage = null;

    try {
      storage = localStorage;
    } catch (e) {}

    var util = {
      setItem: function setItem(token, content) {
        try {
          storage.setItem(token, content);
        } catch (e) {}
      },
      getItem: function getItem(token) {
        var result = null;

        try {
          result = storage.getItem(token);
        } catch (e) {}

        return result;
      },
      removeItem: function removeItem(token) {
        try {
          storage.removeItem(token);
        } catch (e) {}
      }
    };
    return util;
  }();

  var _default = LocalStorage;
  _exports.default = _default;
});